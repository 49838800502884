<template>
  <div class="block-multi-kpi-subtitle" :style="style.title">{{title}}</div>
    <div v-if="kpis.length > 0" class="cluster-element">
      <div v-for="kpi in kpis" :key="kpi" >
        <span style="display: none">{{nb=0}}</span>
        <div v-for="kp in kpi.value" :key="kp" class="cluster-div" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type" @click="callLinkSec">
          <div :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
            <div class="title-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
              {{ Object.keys(kpi.value)[nb] }}
            </div>
            <span style="display: none">{{i=0}}</span>
            <div class="cluster-icon-text" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
              <div class="icon-html-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
                <i :class="kpi.link.icon" class="icon-html-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type"></i>
              </div>
              <div>
                <div v-for="k in kp" :key="k" class="text-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
                  {{Object.keys(kp)[i]}} : {{ k }}
                  <span style="display: none">{{i++}}</span>
                </div>
              </div>
            </div>
          </div>
          <span style="display: none">{{nb++}}</span>
        </div>
      </div>
    </div>
</template>

<script>
import volt from "@/volt";

export default {
  name: "ClusterBlock",
  emits: ["callLinkPri", "callLinkSec"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String
  },
  methods:{
    callLinkPri(){
      volt.list =  {
        name : this.link.name,
        value : this.link.value,
        size : this.link.size,
        type : this.link.type,
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkPri');
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkSec');
    }
  }
}
</script>

<style scoped>
.block-multi-kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 0 0 10px;
  display: flex;
  flex-direction: row;
  /*border: solid 1px #f3751b;*/
}
.cluster-element{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
}
.cluster-div{
  margin: 5px;
  background-color: #e1e6fb;
  /*background-color: #f5f5f5;*/
  /*color: #2e3133;*/
  width: 300px;
  padding: 10px;
  border-radius: 15px;
  height: 110px;
  cursor: pointer;
}
.title-cluster{
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: solid 2px #8098f3;
  text-align: left;
}
.cluster-icon-text{
  /*border: solid 1px #f3751b;*/
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon-html-cluster{
  font-size: 35px;
  width: 50%;
  text-align: center;
}
.text-cluster{
  /*padding: 10px 0 0 100px;*/
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 17px;
}
@media (max-width: 1700px) {
    .cluster-div{
        width: 270px;
        height: 100px;
    }
    .cluster-element{
        justify-content: center;
        flex-wrap: nowrap;
    }
    .title-cluster{
        font-size: 15px;
    }
}
@media (max-width: 1400px) {
  .cluster-div{
    width: 280px;
    height: 100px;
  }
  .cluster-element{
    justify-content: center;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1250px) {
  .cluster-div{
    width: 200px;
    height: 200px;
  }
  .cluster-element{
    justify-content: space-around;
  }
}
@media (max-width: 800px) {
  .cluster-div{
    width: 250px;
    height: 100px;
  }
  .cluster-element{
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
</style>