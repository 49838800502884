/* eslint-disable */

//////////////////////////////////////////////// Variables ///////////////////////////////////////////////////////////////////////////////////

module.exports.list = {};
module.exports.listPop = {};
module.exports.listUser = {};
module.exports.select_vals = {};
module.exports.select_vals_pop = {};
module.exports.sort_vals = {};
module.exports.sort_vals_pop = {};
module.exports.annonce = '';
module.exports.sync = '';
module.exports.annee = '';
module.exports.tableau = '';
module.exports.commune = '';
module.exports.nbr_result = 20;
module.exports.maps = [];
module.exports.feature = {};
module.exports.data_like = {};
module.exports.width = 1;
module.exports.map_filter = 1;
module.exports.unity = 'annonces';


//////////////////////////////////////////////// Functions ///////////////////////////////////////////////////////////////////////////////////

/**
 * @returns {un tableau d'objets ou de valeur}
 * @param {*} name nom de l'élément préfixé css ('.'=>class,''=>voir querySelector)
 * @param {*} attribut Attribut a récupérer (value,html,text,data...)
 * @param {*} bool Récupération d'éléments dynamique ou non pour les class
 */
module.exports.taks = function(name,attribut,bool) {
    if(typeof(attribut)==='undefined'){ attribut = ''; }
    if(typeof(bool)==='undefined'){ bool = true; }
    let elements = "";
    let values = [];
    if (bool && name.substring(0, 1) === ".") {
        elements = document.getElementsByClassName(name.substring(1));
    } else {
        elements = document.querySelectorAll(name);
    }
    if (attribut !== "") {
        if(elements !== ""){
            if (attribut === "text")for (let i = 0; i < elements.length; i++)values.push(elements[i].innerText);
            if (attribut === "html")for (let i = 0; i < elements.length; i++)values.push(elements[i].innerHTML);
            if (attribut === "value")for (let i = 0; i < elements.length; i++)values.push(elements[i].value);
            if (attribut.substring(0, 4) === "data")for (let i = 0; i < elements.length; i++)values.push(elements[i].getAttribute(attribut));
        }
        return values;
    }
    return elements;
}

/**
 * @returns {un objet ou une valeur}
 * @param {*} name nom de l'id
 * @param {*} attribut Attribut a récupérer (value,html,text,data...)
 * @param {*} bool Récupération d'éléments dynamique ou non
 */
module.exports.tak = function(name , attribut = "", bool = true) {
    var value = '';
    let element = "";
    if (bool) {
        element = document.getElementById(name);
    } else {
        element = document.querySelector(name);
    }
    if (attribut !== "") {
        if(element !== ""){
            if (attribut === "text")value=(element.innerText);
            if (attribut === "html")value=(element.innerHTML);
            if (attribut === "value")value=(element.value);
        }
        return value;
    }
    return element;
}

/**
 * @param {*} element tableau d'éléments ou élément
 * @param {*} event nom de l'evenement
 * @param {*} fonc fonction : ()=> perd le this, function() ou nom de la fonction garde le this
 */
module.exports.ael = function(element, event, fonc){
    if(element === 'window'){
        window.addEventListener(event, fonc);
    }else{
        if(element.length === undefined || element.nodeName === 'SELECT'){
            element.addEventListener(event, fonc);
        }else{
            for (let i = 0; i < element.length; i++)element[i].addEventListener(event,  fonc);
        }
    }
}

/**
 * @param {*} element
 * @param {*} css objet de style
 * exemple d'objet de style :
 * let css ={
 *  color:'red',
 *   backgroundColor:'black'
 *   }
 */
module.exports.styl = function(element, css){
    for(var i in css){
        eval('element.style.'+ i +'=\''+css[i]+'\'');
    }
}
// function changeVisibility(obj){
//     obj.style.display = 'hidden';
// }
module.exports.getParameterUrl = function() {
    var results= window.location.search;
    var params = results.split('?')[1];
    if(params !== undefined){
        var paramTab = params.split('&');
        var jsonParamsTAb = [];
        paramTab.forEach((el)=>{
            var param = el.split('=');
            jsonParamsTAb.push('"'+param[0]+'":"'+param[1]+'"');
        });
        var jsonParams = '{'+jsonParamsTAb.join(',')+'}';
        return JSON.parse(jsonParams);
    }else{
        return false;
    }
}
module.exports.jsonToHtml = function(jsonString){
    var visibleSpan = 'if(this.nextSibling.style.display === \'\'){ this.nextSibling.style.display = \'none\';this.innerText = \'+\'}else{ this.nextSibling.style.display = \'\';this.innerText = \'-\'}';
    var geoHtml = jsonString.replaceAll('{','{<span style="position: relative;cursor: pointer;color: #026a42" onclick="">-</span><span style="position: relative;left:15px"></br>');
    var geoHtml2 = geoHtml.replaceAll(',',',</br>');
    var geoHtml3 = geoHtml2.replaceAll('}','</br></span>}');
    var geoHtml4 = geoHtml3.replaceAll('[','[<span style="position: relative;cursor: pointer;color: #b42626" onclick="">+</span><span style="position: relative;left:15px;display: none"></br>');
    var geoHtml5 = geoHtml4.replaceAll(']','</br></span>]');
    var geoHtml6 = geoHtml5.replaceAll(':',' : ');
    var geoHtml7 = geoHtml6.replaceAll('onclick=""','onclick="'+visibleSpan+'"');
    return geoHtml7;
}
module.exports.o = function(proxy){
    return JSON.parse(JSON.stringify(proxy));
}
module.exports.getEnv = function(){
    return new Promise((resolve,reject)=>{
        const url = "/env.json";
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })
}
module.exports.getGlossaire = function(){
    return new Promise((resolve,reject)=>{
        const url = "/glossaire.json";
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })
}
module.exports.getEnvAsync = async function(){
        var res = await fetch("/env.json")
        var sol = await res.json()
        return sol
}
module.exports.getEnvAsyncMenu = async function(){
    var res = await fetch("/env.json")
    var sol = await res.json()
    return sol['menus']
}
module.exports.post = function(path, data, id = "", entity, year){
    return new Promise((resolve, reject) => {
        this.getEnv().then((env) => {
            let url = env.paths.back+path;
            const sessionToken = sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity,
                'x-annee' : year,
                'id': id
            };
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    });
}
module.exports.get = function(path, entity){
    return new Promise((resolve, reject) => {
        this.getEnv().then((env)=>{
            let url = env.paths.back+path;
            const sessionToken= sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity
            };
            var myInit = {
                method: 'GET',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason)=>{
                    reject(reason);
                });
        })
    });
}

module.exports.getBase64 = function(file) {
    return new Promise((resolve, reject) => {
        if (typeof file === "object") {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        } else {
            resolve('none')
        }
    });
}
module.exports.today =  function(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var time = today.getUTCHours();
    var minute = today.getUTCMinutes();
    var second = today.getUTCSeconds();
    var yyyy = today.getFullYear();
    if(dd<10)dd='0'+dd;
    if(mm<10)mm='0'+mm;
    if(time<10)time='0'+time;
    if(second<10)second='0'+second;
    return yyyy+'-'+mm+'-'+dd+' '+time+':'+minute+':'+second;
}
/**
 *
 * @param array
 * @returns {{min: number, max: number}}
 */
module.exports.min_max = function(array){
    var min = 0;
    var max = 0;
    array.forEach((el, index)=>{
        if(el.Value !== undefined){
            if(el.Value.$numberDecimal !== undefined){
                if(index === 0){
                    min = Number.parseFloat(el.Value.$numberDecimal);
                    max = Number.parseFloat(el.Value.$numberDecimal);
                }else{
                    if(Number.parseFloat(el.Value.$numberDecimal) < min)min=Number.parseFloat(el.Value.$numberDecimal);
                    if(Number.parseFloat(el.Value.$numberDecimal) > max)max=Number.parseFloat(el.Value.$numberDecimal);
                }
            }else{
                if(index === 0){
                    min = Number.parseFloat(el.Value);
                    max = Number.parseFloat(el.Value);
                }else{
                    if(Number.parseFloat(el.Value) < min)min=Number.parseFloat(el.Value);
                    if(Number.parseFloat(el.Value) > max)max=Number.parseFloat(el.Value);
                }
            }
        }else{
            if(index === 0){
                min = Number.parseFloat(el);
                max = Number.parseFloat(el);
            }else{
                if(Number.parseFloat(el) < min)min=Number.parseFloat(el);
                if(Number.parseFloat(el) > max)max=Number.parseFloat(el);
            }
        }
    })
    return {min: min, max: max};
}
/**
 * @param inputArr
 * @returns {*}
 */
module.exports.filterArray = function(inputArr){
    var found = {};
    var out = inputArr.filter(function(element){
        return found.hasOwnProperty(element)? false : (found[element]=true);
    });
    return out;
}

/**
 *
 * @param hexa
 * @param part
 * @returns {*[]}
 */
module.exports.degradToHexa = function(hexa, part){
    var middle = Math.floor(part/2);
    var decTab = [];
    var hexaTab = [];
    var tabNew = [];
    var result = [];
    for(var i = 0; i < 7; i++){
        if(i > 0) {
            var c = Number.parseInt(this.hexaToDec(hexa.substring(i, i + 1))) + middle;
            if(c < 16){
                hexaTab.push(c);
            }else{
                hexaTab.push(15);
            }
        }else{
            hexaTab.push(hexa.substring(i, i + 1));
        }
    }
    for(var i = 0; i < part; i++) {
        tabNew[i] = [];
        hexaTab.forEach((el, ii) => {
            if(ii > 0) {
                var e = Math.floor(el - i);
                if(e > 0 ){
                    tabNew[i].push(this.decToHexa(e));
                }else{
                    tabNew[i].push(0);
                }
            }else{
                tabNew[i].push(el);
            }
        })
        result[i] = tabNew[i].join('');
    }
    return(result);
}
module.exports.decToHexa = function(dec){
    var values = {
        '1' : '1',
        '2' : '2',
        '3' : '3',
        '4' : '4',
        '5' : '5',
        '6' : '6',
        '7' : '7',
        '8' : '8',
        '9' : '9',
        '10' : 'a',
        '11' : 'b',
        '12' : 'c',
        '13' : 'd',
        '14' : 'e',
        '15' : 'f'
    }
    return(values[dec])
}
module.exports.hexaToDec = function(hexa){
    var values = {
        '1' : '1',
        '2' : '2',
        '3' : '3',
        '4' : '4',
        '5' : '5',
        '6' : '6',
        '7' : '7',
        '8' : '8',
        '9' : '9',
        'a' : '10',
        'b' : '11',
        'c' : '12',
        'd' : '13',
        'e' : '14',
        'f' : '15'
    }
    return(values[hexa])
}
module.exports.number_format = function(number, decimalSeparator = ',' , thousandSeparator = ' ' ){

    // make sure we have a string
    var result = String(number);

    // split the number in the integer and decimals, if any
    var parts = result.split('.');

    // // if we don't have decimals, add .00
    // if (!parts[1]) {
    //     parts[1] = "00";
    // }

    // reverse the string (1719 becomes 9171)
    result = parts[0].split("").reverse().join("");

    // add thousand separator each 3 characters, except at the end of the string
    result = result.replace(/(\d{3}(?!$))/g, "$1" + thousandSeparator);

    // reverse back the integer and replace the original integer
    parts[0] = result.split("").reverse().join("");

    // recombine integer with decimals
    return parts.join(decimalSeparator);
}
module.exports.firstToUpper = function(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
}
module.exports.onlyFirstToUpper = function(str){
    if(str === undefined || str === '' || str === null || str === 'null' || str === 'undefined'){
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
module.exports.str_prop_sort = function(items, prop){
    items.sort((a, b) => {
        const nameA = a[prop].toUpperCase(); // ignore upper and lowercase
        const nameB = b[prop].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}
module.exports.today_fr =  function(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var time = today.getUTCHours();
    var minute = today.getUTCMinutes();
    var second = today.getUTCSeconds();
    var yyyy = today.getFullYear();
    if(dd<10)dd='0'+dd;
    if(mm<10)mm='0'+mm;
    if(time<10)time='0'+time;
    if(second<10)second='0'+second;
    return dd+'/'+mm+'/'+yyyy;
}