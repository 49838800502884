<template>
    <div class="kpi-component" :id="id">
        <KpiDetailsCO
            :title="title"
            :kpis="kpis"
            :open="open"
            @callList="callList"
            :limit="limit"
            :size="parseInt(gridSize)"
            @syncKpis="syncKpis"
            @callMap="callMap"
            :year="year"
            :datasMap="datasMap"
            :map_filter="map_filter"
            :entity="entity"
            :admin="admin"
            :kpis_type="kpis_type"
        />
    </div>
</template>

<script>
import KpiDetailsCO from "@/components/KpiDetailsCO";

export default {
    name: "KpiComponentCO",
    emits: ["callList", "syncKpis", "callMap"],
    components: {KpiDetailsCO},
    props: {
        id : String,
        limit: Number,
        color: String,
        backgroundColor: String,
        title: String,
        kpis: Object,
        gridCS: String,
        gridCE: String,
        gridRS: String,
        gridRE: String,
        gridSize: String,
        style: Object,
        open: String,
        year: String,
        datasMap: Object,
        map_filter: Number,
        entity: String,
        admin: Number,
        kpis_type: String
    },
    data() {
        return {
            gridStartKpi: 1,
            gridEndKpi: 0,
        }
    },
    mounted() {
    },
    methods: {
        callList() {
            this.$emit('callList');
        },
        syncKpis() {
            this.$emit('syncKpis');
        },
        callMap() {
            this.$emit('callMap');
        }
    }
}
</script>

<style scoped>
.kpi-component {
    background-color: v-bind(backgroundColor);
    color: v-bind(color);
    grid-column-start: v-bind(gridCS);
    grid-column-end: v-bind(gridCE);
    /*display: grid;*/
    /*grid-template-columns: repeat(v-bind(gridSize), 1fr);*/
    /*padding: 10px;*/
    transition: 2s;
    width: 100%;
    /*height: 200px;*/
}

@media (max-width: 1250px) {
    .kpi-component {
        grid-column-start: 1;
        grid-column-end: 21;
    }
}
</style>