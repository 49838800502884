const XLSX = require('xlsx');
const volt = require('../volt');
module.exports.export_data = function(data, headers = [], rgpd, link){
    return new Promise((resolve)=>{
        volt.getGlossaire().then((glos)=>{
            const glossaire = glos.variables_api;
            const wb = XLSX.utils.book_new();
            let data_xlsx = [];
            let data_xlsx_glos = [];
            let data_entete_glos = [];
            let data_entete = [];
            if(data[0]?.feature){
                if(headers.length > 0){
                    headers.forEach((header)=>{
                        if(glossaire[header.field]?.value){
                            data_entete_glos.push(glossaire[header.field].value);
                        }else{
                            data_entete_glos.push(header.field);
                        }
                        data_entete.push(header.field);
                    });
                }else{
                    for(let key in data[0].feature.properties){
                        if(glossaire[key]?.display){
                            data_entete_glos.push(glossaire[key].value);
                        }else{
                            data_entete_glos.push(key);
                        }
                        data_entete.push(key);
                    }
                }
                data_xlsx.push(data_entete_glos);
                data.forEach((_element)=>{
                    let data_row = [];
                    data_entete.forEach((entete)=>{
                        if(glossaire[entete]?.firstUpper){
                            data_row.push(volt.firstToUpper(_element.feature.properties[entete]));
                        }else {
                            data_row.push(_element.feature.properties[entete]);
                        }
                    });
                    data_xlsx.push(data_row);
                })
            }else if(data.ids[0].cluster || data.features[0].feature.properties['nom_intermediaire']){
                if(headers.length > 0){
                    headers.forEach((header)=>{
                        if(glossaire[header.field]?.value){
                            data_entete_glos.push(glossaire[header.field].value);
                        }else{
                            data_entete_glos.push(header.field);
                        }
                        data_entete.push(header.field);
                    });
                }else {
                    for (let key in data[0]) {
                        data_entete.push(key);
                    }
                }
                data_xlsx.push(data_entete_glos);
                data.features.forEach((_element)=>{
                    let data_row = [];
                    data_entete.forEach((entete)=>{
                        if(glossaire[entete]?.firstUpper){
                            data_row.push(volt.firstToUpper(_element.feature.properties[entete]));
                        }else{
                            data_row.push(_element.feature.properties[entete]);
                        }

                    });
                    data_xlsx.push(data_row);
                })
            }
           for(let glo in glossaire){
                let data_row_glos = [];
                if(glossaire[glo].display){
                    data_row_glos.push(glossaire[glo].class);
                    data_row_glos.push(glossaire[glo].value);
                    if(glossaire[glo].title !== '') data_row_glos.push(glossaire[glo].title);
                    else data_row_glos.push(glossaire[glo].value);
                    data_xlsx_glos.push(data_row_glos);
                }
            }
            // const ws = XLSX.utils.aoa_to_sheet([["a","b","c"],[1,2,3],[4,5,6]]);
            const ws = XLSX.utils.aoa_to_sheet(data_xlsx);
            const ws2 = XLSX.utils.aoa_to_sheet(data_xlsx_glos);
            const wsRgpd = XLSX.utils.aoa_to_sheet([[rgpd], [link]]);
            XLSX.utils.book_append_sheet(wb, ws, "DATA");
            XLSX.utils.book_append_sheet(wb, ws2, "GLOSSAIRE");
            XLSX.utils.book_append_sheet(wb, wsRgpd, "RGPD");
            resolve(wb);
        })
    })

}
module.exports.downloadFile = function(wb){
    XLSX.writeFileXLSX(wb, "export.xlsx");
}