<template>
    <div class="input-form">
        <label class="label-input-form" :for="'input-form-'+label" >{{label}}</label>
        <input :title="value" class="input-input-form" :id="'input-form-'+label" :value="value" :disabled = "disabled"/>
    </div>
</template>

<script>

export default {
    name: "InputForm",
    props:{
        label : String,
        value : String,
        disabled:Boolean,
    }
}

</script>



<style scoped>
.input-form{
    width: 100%;
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    height: 100%;
}
.label-input-form{
    //width: 40%;
    width: 50%;
    text-align: right;
    margin: 5px 5px 5px 5px;
}
.input-input-form{
    //width: 60%;
    width: 50%;
    border: none;
    box-shadow: inset 1px 1px 5px #2c3e50;
    border-radius: 5px;
    padding-left: 10px;
}
</style>