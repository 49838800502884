<template>
    <ExportLoader :value1="value_load1" :value2="value_load2" :value3="value_load3"/>
    <div class="btn-header-div">
        <!--      <div>{{ list.title }} {{cluster_id}}</div><ListLoader :type="type"/>-->
        <div v-if="type === 'chart'" class="btn-rapport-div">
            <button class="btn-help-rapport" @click="openVideo"><i class="fa-solid fa-circle-info"></i></button>
            <select id="select-rapport-type" @change="selectRapoortType">
                <option v-if="user.level != 3" value="user">Mes rapports</option>
                <option value="entity">Rapports partagés</option>
            </select>
            <div v-if="rapport_type === 'user'" class="btn-rapport-div">
                <span style="display: none">{{ i = 0 }}</span>
                <div v-for="l in user_list" :key="l">
                    <button class="rapport-btn" :title=" l.title" @click="callUserList" :data-id="l._id"
                            :data-creator="l.user" :data-index="i">{{ l.title.substring(0, 11) + '...' }}
                    </button>
                    <!--            <button class="rapport-btn" @click="callUserList" :data-id="l._id" :data-creator="l.user" :data-index="i">Rapport {{i+1}}</button>-->
                    <span style="display: none">{{ i++ }}</span>
                </div>
            </div>
            <div v-if="rapport_type === 'entity'" class="btn-rapport-div">
                <span style="display: none">{{ i = 0 }}</span>
                <div v-for="l in entity_list" :key="l">
                    <button class="rapport-btn" :title="l.title" @click="callEntityList" :data-id="l._id"
                            :data-creator="l.user" :data-index="i">{{ l.title.substring(0, 11) + '...' }}
                    </button>
                    <!--            <button class="rapport-btn" @click="callEntityList" :data-id="l._id" :data-creator="l.user" :data-index="i">Rapport {{i+1}}</button>-->
                    <span style="display: none">{{ i++ }}</span>
                </div>
            </div>
            <div v-if="rapport_type === 'load'">
                <button class="rapport-btn"><i class="fa-solid fa-spinner fa-spin-pulse"></i></button>
            </div>
            <!--        <button class="rapport-btn" @click="callUserList">Rapport 2</button>-->
            <!--        <button class="rapport-btn" @click="callUserList">Rapport 3</button>-->
            <!--        <button class="rapport-btn" @click="callUserList">Rapport 4</button>-->
            <!--        <button class="rapport-btn" @click="callUserList">Rapport 5</button>-->
        </div>
        <div v-if="type === 'chart'">
            <PopSelect :id="type" :list="list" :values="values_header_pop" @searchLike="searchLike" :entete="entete"
                       :preField="preField" :name="name" @callList="callListFilter" :glossaire="glossaire"></PopSelect>
            <button v-if="user.level != 3 && rapport_type === 'user' && user_list.length < env.limit_list" title="Ajouter une liste"
                    class="btn-option-list-chart" @click="addList"><i class="fas fa-plus"></i></button>
            <button v-if="user.level != 3 && rapport_type === 'entity' && entity_list.length < env.limit_list"
                    title="Ajouter une liste" class="btn-option-list-chart" @click="addList"><i class="fas fa-plus"></i>
            </button>
            <button
                v-if="user.level != 3 && ((user_list.length > 0) || (entity_list.length > 0 && user['_id'] === id_creator)) && list_id !== undefined && list_id !== '' "
                title="Modifier la liste" class="btn-option-list-chart" @click="updateList"><i class="fas fa-pen"></i>
            </button>
            <button
                v-if="user.level != 3 && ((user_list.length > 0) || (entity_list.length > 0 && user['_id'] === id_creator)) && list_id !== undefined && list_id !== '' "
                title="Supprimer la liste" class="btn-option-list-chart" @click="deleteList"><i
                class="far fa-trash-alt"></i></button>
            <button
                v-if="user.level != 3 && ((user_list.length > 0) || (entity_list.length > 0 && user['_id'] === id_creator)) && list_id !== undefined && list_id !== '' "
                title="Enregistrer les filtres sur le rapport" class="btn-option-list-chart"
                @click="updateRapportFilters"><i class="fa-regular fa-floppy-disk"></i></button>
            <button title="Supprimer les filtres" class="btn-option-list-chart" @click="deletePhilter"><i
                class="fas fa-redo"></i></button>
            <button v-if="user.level != 3" title="Traiter toutes les annonces de la liste" class="btn-option-list-chart"
                    @click="callConformites"><i class="fas fa-edit"></i></button>
            <button v-if="user.level != 3" title="Exporter les données" class="btn-option-list-chart"
                    @click="exportDatas"><i class="fas fa-upload"></i></button>
            <button v-if="user.level != 3" title="Exporter la liste" class="btn-option-list-chart"
                    @click="exportDatasList"><i class="fa-solid fa-file-arrow-up"></i></button>
            <button v-if="format === 'list'" class="btn-option-list-chart" title="Vue cartographique" @click="openMap">
                <i class="fas fa-globe"></i></button>
            <button v-if="format === 'map'" class="btn-option-list-chart" title="Vue tableau" @click="callList"><i
                class="fas fa-list-ul"></i></button>
        </div>
    </div>
    <div class="list-annonces">
        <div v-if="type === 'coupdoeil'" class="title-list-annonces">
            <button class="btn-option-list" title="Retour au tableau de bord" @click="closeList"><i
                class="fas fa-arrow-left"></i></button>
            <div class="title-list">{{ list.title }}</div>
            <ListLoader :type="type"/>
        </div>

        <div v-else class="title-list-annonces">
            <div class="title-list" v-if="id_cluster !== ''">{{ list.title }} {{ title }} {{ cluster_id }}</div>
            <div class="title-list"
                 v-else-if="id_cluster === '' && type === 'popup' && list.cluster === 'Multi-loueurs'">Annonce(s) du
                loueur
            </div>
            <div class="title-list"
                 v-else-if="id_cluster === '' && type === 'popup' && list.cluster === 'Multi-meublés'">Annonce(s) à la
                même adresse
            </div>
            <div class="title-list" v-else>{{ list.title }}</div>
            <ListLoader :type="type"/>
        </div>
        <div v-if="type === 'observatoire'" class="btn-list-annonces">
            <button class="btn-option-list-observatoire" v-if="startList === 1" @click="reduceList"><i
                class="fas fa-compress-alt"></i></button>
            <button class="btn-option-list-observatoire" v-else @click="openList"><i class="fas fa-expand-alt"></i>
            </button>
            <button class="btn-option-list-observatoire" @click="closeList"><i class="far fa-times-circle"></i></button>
        </div>


        <div v-if="type === 'coupdoeil'" class="btn-list-annonces">
            <PopSelect :id="type" :list="list" :values="values_header_pop" @searchLike="searchLike" :entete="entete"
                       :preField="preField" :name="name" @callList="callListFilter" :glossaire="glossaire"></PopSelect>
            <button class="btn-option-list" title="Retour au tableau de bord" @click="deletePhilter"><i
                class="fas fa-redo"></i></button>
            <button v-if="user.level != 3" class="btn-option-list" title="Exporter la liste" @click="exportDatas"><i
                class="fas fa-upload"></i></button>
            <button v-if="user.level != 3 && format === 'list' && (list.name === 'plateformRep' || list.name === 'plateformTotalInt'  || list.name === 'plateformTotalIntV2'|| list.name === 'plateform_int_repV2')"
                    class="btn-option-list" :title="'Télécharger tout les fichiers ELAN déposés en '+ year" @click="fileDpotAll"><i class="fa-solid fa-file-arrow-up"></i></button>
            <button v-if="format === 'list' && this.list.name.substring(0,7) !== 'cluster'" class="btn-option-list"
                    title="Vue cartographique" @click="openMap"><i class="fas fa-globe"></i></button>
            <button v-if="format === 'map' && this.list.name.substring(0,7) !== 'cluster'" class="btn-option-list"
                    title="Vue tableau" @click="callList"><i class="fas fa-list-ul"></i></button>
        </div>
        <div v-if="type === 'popup'" class="btn-list-annonces">
            <PopSelect :id="type" :list="list" :values="values_header_pop" @searchLike="searchLike" :entete="entete"
                       :preField="preField" :name="name" @callList="callListFilter" :glossaire="glossaire"></PopSelect>
        </div>


        <div class="header-list-annonces">
            <div class="header-col-list-annonces" v-for="header in list.headers" :key="header" :style="header.style">
                <!--        <div v-if="list.name === 'plateformRep' || list.name === 'plateformTotalInt' || list.name === 'plateformTotalIntV2'  || list.name === 'vs_nbConciergeriesv2' || list.name === 'plateform_int_repV2' || list.name === 'evo_tauxRepConciergeries'" class="header-col-text" :data-field="header.field" :data-preField="header.preField" :data-name="header.name">{{header.name}}</div>-->
                <div v-if="!header.search && !header.filter" :title="glossaire[header.field]?.title"
                     class="header-col-text" :data-field="header.field" :data-preField="header.preField"
                     :data-name="header.name">{{ glossaire[header.field]?.value }}
                </div>
                <!--        <div v-if="!header.search && !header.filter" class="header-col-text" :data-field="header.field" :data-preField="header.preField" :data-name="header.name">{{header.name}}</div>-->
                <!--        <div v-else class="header-col-text click-header" :title="glossaire[header.field]?.title" @click="openPopSelect" :data-field="header.field" :data-preField="header.preField" :data-name="header.name">-->
                <div v-else class="header-col-text">
                    <div v-if="glossaire[header.field]" class="header-col-text click-header"
                         :title="glossaire[header.field]?.title" @click="openPopSelect" :data-field="header.field"
                         :data-preField="header.preField" :data-name="header.name">{{ glossaire[header.field]?.value }}
                    </div>
                    <div v-else class="header-col-text click-header" :title="glossaire[header.field]?.title"
                         @click="openPopSelect" :data-field="header.field" :data-preField="header.preField"
                         :data-name="header.name">{{ header.name }}
                    </div>
                    <!--        <div v-else class="header-col-text click-header" @click="openPopSelect" :data-field="header.field" :data-preField="header.preField" :data-name="header.name">{{header.name}}-->
                    <div v-if="type === 'chart' || type === 'coupdoeil' || type === 'popup'" class="icon-filter-header"
                         :id="'icon-filter-header-'+type+'-'+header.field" :data-field="header.field"
                         :data-preField="header.preField" :data-name="header.name"><i :data-field="header.field"
                                                                                      :data-preField="header.preField"
                                                                                      :data-name="header.name"
                                                                                      class="fas fa-filter"></i></div>
                    <div v-if="type === 'chart' || type === 'coupdoeil' || type === 'popup'" class="icon-filter-header"
                         :id="'icon-sort-max-header-'+type+'-'+header.field" :data-field="header.field"
                         :data-preField="header.preField" :data-name="header.name"><i :data-field="header.field"
                                                                                      :data-preField="header.preField"
                                                                                      :data-name="header.name"
                                                                                      class="fa-solid fa-arrow-down-wide-short"></i>
                    </div>
                    <div v-if="type === 'chart' || type === 'coupdoeil' || type === 'popup'" class="icon-filter-header"
                         :id="'icon-sort-min-header-'+type+'-'+header.field" :data-field="header.field"
                         :data-preField="header.preField" :data-name="header.name"><i :data-field="header.field"
                                                                                      :data-preField="header.preField"
                                                                                      :data-name="header.name"
                                                                                      class="fa-solid fa-arrow-down-short-wide"></i>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="(type !== 'observatoire' && format === 'list') || type === 'observatoire' "
             class="list-list-annonces">
            <span style="display: none">{{ i = 1 }}</span>
            <span style="display: none">{{ re = 1 }}</span>
            <!--      <div class="annonce-line" v-for="annonce in list.annonces" :key="annonce" :data-annonce="annonce.annonce._id" :style="'grid-row-start:'+ re +';grid-row-end:'+(re + 3)" @click="callAnnonce(annonce.annonce._id)">-->
            <div class="annonce-line" v-for="annonce in list.annonces" :key="annonce"
                 :data-first="annonce.annonce.fields[0].value" :data-annonce="annonce.annonce._id"
                 :id="'annonce-line-'+annonce.annonce._id"
                 @click="callAnnonce(annonce.annonce._id, annonce.annonce.fields[0].value,this)">
                <span style="display: none">{{ re = (re + 3) }}</span>
                <span style="display: none">{{ nb = 0 }}</span>
                <div class="list-col-list-annonces" v-for="ann in annonce.annonce.fields" :key="ann"
                     :style="list.headers[nb].style">
                    <div v-if="list.headers[nb].field === 'site_web'" class="list-col-text"
                         style="color:#6a6af6;text-decoration-line: underline" @click="el_preventDefault"
                         :data-href="ann.value">{{ ann.value }}
                    </div>
                    <div v-else-if="list.headers[nb].field === 'id_num' && ann.value !== ''" class="list-col-text"
                         style="color:#6a6af6;text-decoration-line: underline" @click="callDossierNE"
                         :data-value="annonce.annonce._id">{{ ann.value }} <i
                        class="fa-solid fa-arrow-up-right-from-square" :data-value="annonce.annonce._id"></i></div>
                    <div v-else-if="list.headers[nb].field === 'affichage_cu' && ann.value !== 'Manquant' && ann.value !== 'Non concernée' && ann.value !== 'Infos Numéro d\'enregistrement à vérifier'" class="list-col-text"
                         style="color:#6a6af6;text-decoration-line: underline" @click="callDossierCU"
                         :data-value="annonce.annonce._id">{{ ann.value }} <i
                        class="fa-solid fa-arrow-up-right-from-square" :data-value="annonce.annonce._id"></i></div>
                    <div v-else class="list-col-text" :style="'color:'+ann.color">{{ ann.value }}</div>
                    <span style="display: none">{{ nb++ }}</span>
                </div>
                <span style="display: none">{{ i++ }}</span>
            </div>
        </div>
        <div v-if="type !== 'observatoire' && format === 'map'" class="map-list-annonces">
            <MapLoader/>
            <MapsVue
                title='ol'
                gridCS="1"
                gridCE="21"
                gridRS="1"
                gridRE="31"
                @click="callMap"
            />
        </div>
        <div v-if="format === 'list' && list.name !== 'plateformRep' && list.name !== 'plateformTotalInt'  && list.name !== 'plateformTotalIntV2' && list.name !== 'vs_nbConciergeriesv2' && list.name !== 'plateform_int_repV2' && list.name !== 'evo_tauxRepConciergeries'"
            class="footer-list-annonces">
            <div v-if="offset > 0" class="btn-list-last" @click="callLinkLast">
                <div class="page-encour"><i class="fas fa-chevron-left"></i></div>
            </div>
            <div v-else class="btn-list-last-desactiv">
                <div class="page-encour"><i class="fas fa-chevron-left"></i></div>
            </div>
            <div v-if="(offset + limit) < list.size " class="pagin-list-state">
                <div class="page-encour"><span>{{ type_result }} {{ offset + 1 }} - {{ offset + limit }} </span> /
                    <span> {{ list.size }} </span></div>
            </div>
            <div v-else class="pagin-list-state">
                <div class="page-encour"><span>{{ type_result }} {{ offset + 1 }} - {{ list.size }} </span> /
                    <span> {{ list.size }} </span></div>
            </div>
            <div v-if="offset + limit < parseInt(list.size)" class="btn-list-next" @click="callLinkNext">
                <div class="page-encour"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div v-else class="btn-list-next-desactiv">
                <div class="page-encour"><i class="fas fa-chevron-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import volt, {firstToUpper} from '../volt';
import ListLoader from "@/components/ListLoader";
import PopSelect from "@/elements/PopSelect";
import MapsVue from "@/components/MapsVue";
import MapLoader from "@/components/MapLoader";
import ExportLoader from "@/components/ExportLoader";

const xlsx = require('@/services/sheet');
export default {
    name: "ListAnnonces",
    components: {MapLoader, PopSelect, ListLoader, MapsVue, ExportLoader},
    emits: ["callList", "chargeList", "closeList", "callAnnonce", "callMenuChart", "callConformites", "callAnnonceCluster", "callMap", "openMap", "resetListUser", "callAnnoncePlateforme", "openVideo", "callDossierCU", "callDossierNE"],
    props: {
        list: Object,
        gridCS: String,
        gridCE: String,
        gridRS: String,
        gridRE: String,
        limit: Number,
        type: String,
        entity: String,
        year: String,
        glossaire: Object,
        ids: Object,
        cluster: String,
        id_cluster: String,
        id_annonce: String,
        specif: String,
        user_list: Object,
        entity_list: Object,
        user: Object,
        env: Object,
        entity_select: Object
    },
    watch: {
        list() {
            this.offset = this.list.offset;
            if (this.list.name.substring(0, 7) === 'cluster') {
                this.type_result = 'Résultats';
            } else {
                this.type_result = 'Annonces';
            }
        },
        id_cluster() {
            if (this.list.name && this.list.name.substring(0, 7) === 'cluster') {
                this.cluster_id = 'N° ' + this.id_cluster;
            } else {
                this.cluster_id = '';
            }
        },
        user_list() {
        }
    },
    mounted() {
        this.list_id = volt.listUser._id;
        this.startList = this.gridRS;
        setTimeout(()=>{
            if( this.env.rgpd[this.entity] ){
                this.rgpd_link = this.env.rgpd[this.entity];
            }else{
                this.rgpd_link = this.env.rgpd['all'];
            }
        }, 500);


        if (volt.tak('select-rapport-type')) {
            if (volt.listUser.type) {
                this.rapport_type = volt.listUser.type;
                volt.tak('select-rapport-type').value = this.rapport_type;
            } else {
                volt.tak('select-rapport-type').value = 'user';
                this.rapport_type = 'user';
            }
            if (this.user.level == 3) {
                volt.tak('select-rapport-type').value = 'entity';
                this.rapport_type = 'entity';
            }
        }
        if (this.type === 'chart') {
            this.headerEnd = 4;
            this.startList = 2;
            this.startAnn = 4;
            this.endList = 30;
            this.startFooter = 30
        } else if (this.type === 'observatoire') {
            this.headerEnd = 4;
            this.startAnn = 4;
            this.endList = 29;
            this.startFooter = 29;
        } else if (this.type === 'coupdoeil') {
            this.headerEnd = 4;
            this.startList = 1;
            this.startAnn = 4;
            this.endList = 30;
            this.startFooter = 30;
        } else {
            this.headerEnd = 6;
            this.startAnn = 6;
            this.endList = 28;
            this.startFooter = 28;
        }
        if (this.type === 'chart') {
            this.refreshIconHeader();
            this.selectAutoList();
        }
        if (this.type === 'coupdoeil') this.refreshIconHeader();

        this.selectAnnonce();
    },
    updated() {
        if (this.type === 'chart') {
            this.list_id = volt.listUser._id;
            this.refreshIconHeader();
            this.selectAutoList();
        }
        if (this.type === 'coupdoeil') this.refreshIconHeader();
        if (this.type === 'popup') this.refreshIconHeader();
        this.selectAnnonce();
    },
    data() {
        return {
            value_load1: 0,
            value_load2: 0,
            value_load3: 0,
            title: '',
            offset: 0,
            order: 1,
            by: '',
            headerEnd: 4,
            startAnn: 4,
            startList: 1,
            endList: 29,
            startFooter: 29,
            values_header_pop: [],
            entete: '',
            name: '',
            preField: '',
            type_result: "Annonces",
            cluster_id: '',
            format: 'list',
            list_id: volt.listUser._id,
            rapport_type: 'user',
            id_creator: volt.listUser.user,
            rgpd_link: '',
            rgpd_pop:'Vous vous apprêtez à exporter des données à caractère personnel. Ces données sont mises à la disposition de la commune de '+firstToUpper(this.entity)+' en qualité de responsable de traitement et doivent être traitées dans le cadre des finalités relatives à l’observatoire ELAN pour lesquelles elles ont été collectées (article L.324-2-1 du Code du Tourisme). Veillez au respect des règles définies de confidentialité, de conservation, de sécurité et de sauvegarde. Les personnes concernées bénéficient de droits (d’accès, de modification, de suppression notamment) qui peuvent être exercés auprès de votre DPO.'
        }
    },
    methods: {
        fileDpotAll(){
            console.log(this.entity);
            fetch(this.env.paths.back+'depotFile?entity='+this.entity_select._id+'&year='+this.year).then((res) => {
                res.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'fichiers_elan_'+this.entity+'_'+this.year+'.zip';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
            }).catch((reason) => {
                console.log(reason);
            })
        },
        el_preventDefault(event) {
            event.preventDefault();
            event.stopPropagation();
            open(event.target.getAttribute('data-href'), '_blank');
        },
        openVideo() {
            this.$emit('openVideo');
        },
        firstToUpperCase(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        exportDatasList() {
            if (confirm(this.rgpd_pop)) {
                let interval1 = setInterval(() => {
                    if (this.value_load1 < 87) {
                        this.value_load1++
                    } else {
                        clearInterval(interval1)
                    }
                }, 500);
                if (this.ids === undefined || this.ids.length === 0) {
                    volt.get('obs/elan/find?q=annee&facet=' + this.year, this.entity).then((data) => {
                        this.value_load1 = 89;
                        this.value_load1 = 100;
                        let interval2 = setInterval(() => {
                            if (this.value_load2 < 100) {
                                this.value_load2 += Math.floor(Math.random() * 7);
                            } else {
                                this.value_load2 = 100;
                                clearInterval(interval2)
                            }
                        }, 200)
                        xlsx.export_data(data, this.list.headers, this.rgpd_pop, this.rgpd_link).then((file_source) => {
                            let interval3 = setInterval(() => {
                                if (this.value_load2 > 99) {
                                    if (this.value_load3 < 95) {
                                        this.value_load3 += Math.floor(Math.random() * 7);
                                    } else {
                                        xlsx.downloadFile(file_source);
                                        clearInterval(interval3);
                                        this.value_load1 = 0;
                                        this.value_load2 = 0;
                                        this.value_load3 = 0;
                                    }
                                }
                            }, 500)
                        }).catch((reason) => {
                            console.log(reason);
                            clearInterval(interval1);
                            this.value_load1 = 0;
                            this.value_load2 = 0;
                            this.value_load3 = 0;
                        });
                    }).catch((reason) => {
                        console.log(reason);
                        clearInterval(interval1);
                        this.value_load1 = 0;
                        this.value_load2 = 0;
                        this.value_load3 = 0;
                    })
                } else {
                    // console.log(this.ids);
                    let ids_tab = [];
                    this.ids.forEach((id) => {
                        if (id._id) {
                            ids_tab.push(id._id)
                        } else {
                            ids_tab.push(id)
                        }
                    })
                    volt.post('obs/elan_ids/find', {"ids": ids_tab}, '', this.entity).then((data) => {
                        this.value_load1 = 89;
                        this.value_load1 = 100;
                        let interval2 = setInterval(() => {
                            if (this.value_load2 < 100) {
                                this.value_load2 += Math.floor(Math.random() * 7);
                            } else {
                                this.value_load2 = 100;
                                clearInterval(interval2)
                            }
                        }, 200)
                        xlsx.export_data(data, this.list.headers,  this.rgpd_pop, this.rgpd_link).then((file_source) => {
                            let interval3 = setInterval(() => {
                                if (this.value_load2 > 99) {
                                    if (this.value_load3 < 90) {
                                        this.value_load3 += Math.floor(Math.random() * 7);
                                    } else {
                                        xlsx.downloadFile(file_source);
                                        clearInterval(interval3);
                                        this.value_load1 = 0;
                                        this.value_load2 = 0;
                                        this.value_load3 = 0;
                                    }
                                }
                            }, 500)
                        }).catch((reason) => {
                            console.log(reason);
                            clearInterval(interval1);
                            this.value_load1 = 0;
                            this.value_load2 = 0;
                            this.value_load3 = 0;
                        });
                    }).catch((reason) => {
                        console.log(reason);
                        clearInterval(interval1);
                        this.value_load1 = 0;
                        this.value_load2 = 0;
                        this.value_load3 = 0;
                    })
                }
            } else {
                return;
            }
        },
        exportDatas() {
            if (confirm(this.rgpd_pop)) {
                let interval1 = setInterval(() => {
                    if (this.value_load1 < 87) {
                        this.value_load1++
                    } else {
                        clearInterval(interval1)
                    }
                }, 500);
                if (this.ids === undefined || this.ids.length === 0) {
                    volt.get('obs/elan/find?q=annee&facet=' + this.year, this.entity).then((data) => {
                        this.value_load1 = 89;
                        this.value_load1 = 100;
                        let interval2 = setInterval(() => {
                            if (this.value_load2 < 100) {
                                this.value_load2 += Math.floor(Math.random() * 7);
                            } else {
                                this.value_load2 = 100;
                                clearInterval(interval2)
                            }
                        }, 200)
                        xlsx.export_data(data, [],  this.rgpd_pop,this.rgpd_link).then((file_source) => {
                            let interval3 = setInterval(() => {
                                if (this.value_load2 > 99) {
                                    if (this.value_load3 < 95) {
                                        this.value_load3 += Math.floor(Math.random() * 7);
                                    } else {
                                        xlsx.downloadFile(file_source);
                                        clearInterval(interval3);
                                        this.value_load1 = 0;
                                        this.value_load2 = 0;
                                        this.value_load3 = 0;
                                    }
                                }
                            }, 500)
                        }).catch((reason) => {
                            console.log(reason);
                            clearInterval(interval1);
                            this.value_load1 = 0;
                            this.value_load2 = 0;
                            this.value_load3 = 0;
                        });
                    }).catch((reason) => {
                        console.log(reason);
                        clearInterval(interval1);
                        this.value_load1 = 0;
                        this.value_load2 = 0;
                        this.value_load3 = 0;
                    })
                } else if (this.ids[0]['cluster'] || this.list.features[0].feature.properties['nom_intermediaire']) {
                    this.value_load1 = 89;
                    this.value_load1 = 100;
                    let interval2 = setInterval(() => {
                        if (this.value_load2 < 100) {
                            this.value_load2 += Math.floor(Math.random() * 7);
                        } else {
                            this.value_load2 = 100;
                            clearInterval(interval2)
                        }
                    }, 200)
                    xlsx.export_data(this.list, this.list.headers,  this.rgpd_pop, this.rgpd_link).then((file_source) => {
                        let interval3 = setInterval(() => {
                            if (this.value_load2 > 99) {
                                if (this.value_load3 < 90) {
                                    this.value_load3 += Math.floor(Math.random() * 7);
                                } else {
                                    xlsx.downloadFile(file_source);
                                    clearInterval(interval3);
                                    this.value_load1 = 0;
                                    this.value_load2 = 0;
                                    this.value_load3 = 0;
                                }
                            }
                        }, 500)
                    }).catch((reason) => {
                        console.log(reason);
                        clearInterval(interval1);
                        this.value_load1 = 0;
                        this.value_load2 = 0;
                        this.value_load3 = 0;
                    });
                } else {
                    let ids_tab = [];
                    this.ids.forEach((id) => {
                        if (id._id) {
                            ids_tab.push(id._id)
                        } else {
                            ids_tab.push(id)
                        }
                    })
                    volt.post('obs/elan_ids/find', {"ids": ids_tab}, '', this.entity).then((data) => {
                        this.value_load1 = 89;
                        this.value_load1 = 100;
                        let interval2 = setInterval(() => {
                            if (this.value_load2 < 100) {
                                this.value_load2 += Math.floor(Math.random() * 7);
                            } else {
                                this.value_load2 = 100;
                                clearInterval(interval2)
                            }
                        }, 200)
                        xlsx.export_data(data, [],  this.rgpd_pop, this.rgpd_link).then((file_source) => {
                            let interval3 = setInterval(() => {
                                if (this.value_load2 > 99) {
                                    if (this.value_load3 < 90) {
                                        this.value_load3 += Math.floor(Math.random() * 7);
                                    } else {
                                        xlsx.downloadFile(file_source);
                                        clearInterval(interval3);
                                        this.value_load1 = 0;
                                        this.value_load2 = 0;
                                        this.value_load3 = 0;
                                    }
                                }
                            }, 500)
                        }).catch((reason) => {
                            console.log(reason);
                            clearInterval(interval1);
                            this.value_load1 = 0;
                            this.value_load2 = 0;
                            this.value_load3 = 0;
                        });
                    }).catch((reason) => {
                        console.log(reason);
                        clearInterval(interval1);
                        this.value_load1 = 0;
                        this.value_load2 = 0;
                        this.value_load3 = 0;
                    })
                }
            }
        },
        openMap() {
            this.format = 'map';
            setTimeout(() => {
                this.$emit('openMap', {format: this.format});
            }, 200);
        },
        callMap() {
            setTimeout(() => {
                this.$emit('callMap');
            }, 200);
        },
        callList() {
            this.format = 'list';
            if (this.type === 'chart') this.refreshIconHeader();
            if (this.type === 'coupdoeil') this.refreshIconHeader();
            if (this.type === 'popup') this.refreshIconHeader();
            volt.list.offset = this.offset;
            setTimeout(() => {
                this.$emit('callList');
                if (this.format === 'map') {
                    this.format = 'list';
                }
            }, 500);
        },
        deletePhilter() {
            volt.select_vals = {};
            volt.sort_vals = {};
            this.callListFilter();
        },
        refreshIconHeader() {
            if (this.type === "popup") {
                for (let key in volt.select_vals_pop) {
                    if (volt.tak('icon-filter-header-' + this.type + '-' + key)) volt.tak('icon-filter-header-' + this.type + '-' + key).style.display = 'block';
                }
                for (let key in volt.sort_vals_pop) {
                    if (volt.sort_vals_pop[key]['order'] === '-1') {
                        if (volt.tak('icon-sort-max-header-' + this.type + '-' + key)) volt.tak('icon-sort-max-header-' + this.type + '-' + key).style.display = 'block';
                    }
                    if (volt.sort_vals_pop[key]['order'] === '1') {
                        if (volt.tak('icon-sort-min-header-' + this.type + '-' + key)) volt.tak('icon-sort-min-header-' + this.type + '-' + key).style.display = 'block';
                    }
                }
            } else {
                for (let key in volt.select_vals) {
                    if (volt.tak('icon-filter-header-' + this.type + '-' + key)) volt.tak('icon-filter-header-' + this.type + '-' + key).style.display = 'block';
                }
                for (let key in volt.sort_vals) {
                    if (volt.sort_vals[key]['order'] === '-1') {
                        if (volt.tak('icon-sort-max-header-' + this.type + '-' + key)) volt.tak('icon-sort-max-header-' + this.type + '-' + key).style.display = 'block';
                    }
                    if (volt.sort_vals[key]['order'] === '1') {
                        if (volt.tak('icon-sort-min-header-' + this.type + '-' + key)) volt.tak('icon-sort-min-header-' + this.type + '-' + key).style.display = 'block';
                    }
                }
            }
        },
        callListFilter() {
            setTimeout(() => {
                this.$emit('chargeList', this.list);
            }, 200);
        },
        // callMenuChart(){
        //   setTimeout(()=>{ this.$emit('callMenuChart');},200);
        // },
        callConformites() {
            setTimeout(() => {
                this.$emit('callConformites');
            }, 200);
        },
        callLinkLast() {
            this.format = 'list';
            if (this.offset > 0) {
                this.offset = this.offset - this.limit;
                if (this.offset < 0) this.offset = 0;
                if (this.type === 'popup') {
                    volt.listPop.offset = this.offset;
                } else {
                    volt.list.offset = this.offset;
                }
                setTimeout(() => {
                    this.$emit('callList');
                    if (this.format === 'map') {
                        this.format = 'list';
                    }
                }, 500);
            }
        },
        callLinkNext() {
            this.offset = this.offset + this.limit;
            if (this.type === 'popup') {
                volt.listPop.offset = this.offset;
            } else {
                volt.list.offset = this.offset;
            }
            setTimeout(() => {
                this.$emit('callList');
                if (this.format === 'map') {
                    this.format = 'list';
                }
            }, 500);
        },
        closeList() {
            volt.data_like = {};
            volt.sort_vals = {};
            volt.select_vals = {};
            this.$emit('closeList', false);
        },
        openList() {
            volt.tak('map-ol').style.display = 'none';
            this.headerEnd = 3;
            this.startList = 1;
            this.startAnn = 3;
            this.endList = 20;
            this.startFooter = 20
        },
        reduceList() {
            volt.tak('map-ol').style.display = '';
            this.headerEnd = 4;
            this.startAnn = 4;
            this.startList = this.gridRS;
            this.endList = 19;
            this.startFooter = 19;
        },
        selectAnnonce() {
            volt.taks('.annonce-line', '', false).forEach((el) => {
                el.style.backgroundColor = '';
            })
            if (volt.tak('annonce-line-' + this.id_annonce)) volt.tak('annonce-line-' + this.id_annonce).style.backgroundColor = 'rgba(245,245,245,0.70)';
        },
        callAnnonce(ann, plateforme) {
            // volt.taks('.annonce-line', '', false).forEach((el)=>{
            //   el.style.backgroundColor = '';
            // })
            // if(volt.tak('.annonce-line-'+ann))volt.tak('annonce-line-'+this.id_annonce).style.backgroundColor = 'rgba(237,104,104,0.1)';
            if (ann['cluster']) {
                setTimeout(() => {
                    this.$emit('callAnnonceCluster', {type_cluster: this.list.pop_list, cluster: ann['cluster']})
                }, 100);
            } else if (this.cluster !== '' && this.id_cluster !== '' && this.cluster !== undefined && this.id_cluster !== undefined) {
                volt.annonce = ann;
                setTimeout(() => {
                    this.$emit('callAnnonceCluster', {
                        type_cluster: this.cluster,
                        id_cluster: this.id_cluster,
                        annonce_id: ann
                    })
                }, 100);
            } else if (this.list.name === 'plateformRep' || this.list.name === 'plateformTotalInt' || this.list.name === 'plateformTotalIntV2' || this.list.name === 'vs_nbConciergeriesv2' || this.list.name === 'plateform_int_repV2' || this.list.name === 'evo_tauxRepConciergeries') {
                setTimeout(() => {
                    this.$emit('callAnnoncePlateforme', plateforme);
                }, 100);
            } else if (this.list.name === 'clusterAdresse' || this.list.name === 'vs_conciergerie_multi_meuble') {
                volt.annonce = ann;
                setTimeout(() => {
                    this.$emit('callAnnonce', 'meublés')
                }, 100);
            } else {
                volt.annonce = ann;
                setTimeout(() => {
                    this.$emit('callAnnonce')
                }, 100);
            }
        },
        callDossierCU(e) {
            e.preventDefault();
            e.stopPropagation();
            setTimeout(() => {
                this.$emit('callDossierCU', {num_dossier: e.target.getAttribute('data-value')});
            }, 200)
        },
        callDossierNE(e) {
            e.preventDefault();
            e.stopPropagation();
            setTimeout(() => {
                this.$emit('callDossierNE', {num_dossier: e.target.getAttribute('data-value')});
            }, 200)
        },
        searchLike() {
            let add_select_vals = 0;
            if (volt.tak('add-select-vals-bool')) {
                add_select_vals = volt.tak('add-select-vals-bool').getAttribute('data-value');
            }
            let like = volt.tak('pop-select-input-' + this.type).value;
            if (this.preField === null) {
                this.preField = 'feature.properties';
            }
            // if(this.preField === 'not')this.preField = '';
            this.values_header_pop = [];
            if (this.list.name === 'user') {
                if (Object.keys(volt.select_vals).length > 0 && add_select_vals === '0') {
                    //request with filters
                    const data = {
                        by: '',
                        order: '',
                        limit: "0",
                        offset: "0",
                        filters: volt.select_vals,
                        entete: this.entete,
                        q: this.preField !== 'not' && this.preField !== '' ? this.preField + "." + this.entete : this.entete,
                        facet: like,
                        annee: this.year,
                        type_return: "values",
                        list_name: this.list.name
                    }
                    volt.post("obs/elan/find/filter/like", data, "", this.entity, this.year).then((values_header_pop) => {
                        if (values_header_pop.values.length > 0) {
                            this.values_header_pop = values_header_pop.values;
                        } else {
                            this.values_header_pop = ['Aucun résultat'];
                        }
                    }).catch((reason) => {
                        console.log(reason);
                    })
                } else {
                    let q = this.preField !== 'not' && this.preField !== '' ? this.preField + "." + this.entete : this.entete;
                    if (like !== '') {
                        volt.get("obs/like/values/?entete=" + this.entete + "&q=" + q + "&facet=" + like + "&preField=" + this.preField  +"&entete"+this.entete+ "&list_name=" + this.list.name + "&annee=" + this.year, this.entity).then((values_header_pop) => {
                            if (values_header_pop.values.length > 0) {
                                this.values_header_pop = values_header_pop.values;
                            } else {
                                this.values_header_pop = ['Aucun résultat'];
                            }
                        }).catch((reason) => {
                            console.log(reason);
                        })
                    } else {
                        volt.get("obs/elan/values/find/?q=annee&facet=" + this.year + "&entete=" + this.entete + "&preField=" + this.preField +"&entete"+this.entete +"&list_name=" + this.list.name, this.entity).then((values_header_pop) => {
                            if (values_header_pop.values.length > 0) {
                                this.values_header_pop = values_header_pop.values;
                            } else {
                                this.values_header_pop = ['Aucun résultat'];
                            }
                        }).catch((reason) => {
                            console.log(reason);
                        })
                    }
                }
            } else {
                let data = {};
                if (Object.keys(volt.select_vals).length > 0 && add_select_vals === '0') {
                    data = {
                        by: '',
                        order: '',
                        limit: "0",
                        offset: "0",
                        data_select: volt.select_vals,
                        entete: this.entete,
                        q: this.list.q,
                        data_like: {prefield: this.preField, field: this.entete, value: like},
                        facet: this.list.facet,
                        annee: this.year,
                        type_return: "values",
                        type: this.list.name
                    }
                } else {
                    data = {
                        by: '',
                        order: '',
                        limit: "0",
                        offset: "0",
                        data_select: {},
                        entete: this.entete,
                        q: this.list.q,
                        data_like: {prefield: this.preField, field: this.entete, value: like},
                        facet: this.list.facet,
                        annee: this.year,
                        type_return: "values",
                        type: this.list.name
                    }
                }
                volt.post("obs/elan/kpi/find_values", data, "", this.entity, this.year).then((values_header_pop) => {
                    if (values_header_pop.values.length > 0) {
                        this.values_header_pop = values_header_pop.values;
                    } else {
                        this.values_header_pop = ['Aucun résultat'];
                    }
                }).catch((reason) => {
                    console.log(reason);
                })
            }
        },
        openPopSelect(e) {
            if ((this.type === 'chart' || this.type === 'coupdoeil' || this.type === 'popup') && volt.tak('pop-select-' + this.type)) {
                if (volt.tak('pop-select-input-' + this.type)) volt.tak('pop-select-input-' + this.type).value = '';
                let popSelect = volt.tak('pop-select-' + this.type);
                this.values_header_pop = [];
                this.entete = e.target.getAttribute('data-field');
                this.name = e.target.getAttribute('data-name');
                this.preField = e.target.getAttribute('data-preField');
                volt.taks('.header-col-text', '', false).forEach((el) => {
                    el.style.color = ''
                })
                // let scrollWidth = volt.tak('pop-select').scrollWidth;
                let scrollWidth = popSelect.scrollWidth;
                let screenWidth = screen.width;
                let startPop = e.target.getBoundingClientRect()['left'];
                if ((scrollWidth + startPop) > screenWidth) {
                    startPop = screenWidth - scrollWidth;
                }
                let positionPop = startPop + 'px';
                popSelect.style.height = '70%';
                popSelect.style.left = positionPop;
                e.target.style.color = "#a23d06";
                if (this.type !== 'popup') {
                    if (this.list.name === 'user') {
                        if (Object.keys(volt.select_vals).length > 0) {
                            //request with filters
                            const data = {
                                by: '',
                                order: '',
                                limit: "0",
                                offset: "0",
                                cluster: this.id_cluster,
                                filters: volt.select_vals,
                                entete: this.entete,
                                annee: this.year,
                                type_return: "values",
                                verif_size: '0',
                                list_name: this.list.name
                            }
                            volt.post("obs/elan/find/filter", data, "", this.entity, this.year).then((values_header_pop) => {
                                if (values_header_pop.values.length > 0) {
                                    this.values_header_pop = values_header_pop.values;
                                } else {
                                    this.values_header_pop = ['Aucun résultat'];
                                }
                            }).catch((reason) => {
                                console.log(reason);
                            })
                        } else {
                            volt.get("obs/elan/values/find/?q=annee&facet=" + this.year + "&entete=" + this.entete + "&preField=" + this.preField + "&list_name=" + this.list.name, this.entity).then((values_header_pop) => {
                                if (values_header_pop.values.length > 0) {
                                    this.values_header_pop = values_header_pop.values;
                                } else {
                                    this.values_header_pop = ['Aucun résultat'];
                                }
                            }).catch((reason) => {
                                console.log(reason);
                            })
                        }
                    } else {
                        let data = {
                            by: '',
                            order: '',
                            limit: "0",
                            offset: "0",
                            cluster: this.id_cluster,
                            q: this.list.q,
                            facet: this.list.facet,
                            data_select: volt.select_vals,
                            entete: this.entete,
                            annee: this.year,
                            type_return: "values",
                            verif_size: '0',
                            type: this.list.name
                        }
                        volt.post("obs/elan/kpi/find_values", data, "", this.entity, this.year).then((values_header_pop) => {
                            if (values_header_pop.values.length > 0) {
                                this.values_header_pop = values_header_pop.values;
                            } else {
                                this.values_header_pop = ['Aucun résultat'];
                            }
                        }).catch((reason) => {
                            console.log(reason);
                        })
                    }
                } else {
                    let data = {
                        by: '',
                        order: '',
                        limit: "0",
                        offset: "0",
                        cluster: this.id_cluster,
                        q: this.list.q,
                        facet: this.list.facet,
                        data_select: volt.select_vals_pop,
                        entete: this.entete,
                        annee: this.year,
                        type_return: "values",
                        verif_size: '0',
                        type: this.list.name
                    }
                    volt.post("obs/elan/kpi/find_values", data, "", this.entity, this.year).then((values_header_pop) => {
                        if (values_header_pop.values.length > 0) {
                            this.values_header_pop = values_header_pop.values;
                        } else {
                            this.values_header_pop = ['Aucun résultat'];
                        }
                    }).catch((reason) => {
                        console.log(reason);
                    })
                }
                setTimeout(() => {
                    volt.tak('pop-select-content-' + this.type).style.display = 'flex';
                }, 500)
            } else {
                console.log(this.type);
            }
        },
        addList() {
            setTimeout(() => {
                this.$emit('callMenuChart', '');
            }, 200);
        },
        updateList() {
            setTimeout(() => {
                this.$emit('callMenuChart', volt.listUser._id);
            }, 200);
        },
        deleteList() {
            if (confirm('Voulez-vous vraiment supprimer cette liste ?')) {
                if (this.user._id === volt.listUser.user) {
                    let data = {
                        id: volt.listUser._id,
                        year: this.year
                    }
                    volt.post("obs/list/delete", data, "", this.entity, this.year).then((response) => {
                        console.log(response);
                        this.$emit('resetListUser');
                        setTimeout(() => {
                            if (this.user_list.length > 0) {
                                volt.listUser = this.user_list[0];
                                this.$emit('chargeList');
                            } else {
                                volt.listUser = {};
                                this.$emit('chargeList');
                            }
                        }, 500);
                    }).catch((reason) => {
                        console.log(reason);
                    })
                } else {
                    alert('Vous n\'avez pas les droits pour supprimer cette liste');
                }
            }
        },
        selectRapoortType(e) {
            volt.sort_vals = {};
            volt.select_vals = {};
            volt.listUser._id = '';
            this.rapport_type = 'load';
            this.list_id = '';
            volt.taks('.rapport-btn', '', false).forEach((btn) => {
                btn.style.backgroundColor = '';
            })
            setTimeout(() => {
                this.rapport_type = e.target.value;
                volt.taks('.rapport-btn', '', false).forEach((btn) => {
                    btn.style.backgroundColor = '';
                })
            }, 500)
        },
        callUserList(e) {
            this.deletePhilter();
            volt.taks('.rapport-btn', '', false).forEach((btn) => {
                btn.style.backgroundColor = '';
            })
            e.target.style.backgroundColor = '#f3b61b';
            this.list_id = e.target.getAttribute('data-id');
            this.id_creator = e.target.getAttribute('data-creator');
            this.title = this.user_list[e.target.getAttribute('data-index')].title;
            volt.listUser = this.user_list[e.target.getAttribute('data-index')];
            if (this.user_list[e.target.getAttribute('data-index')].filters) volt.select_vals = volt.o(this.user_list[e.target.getAttribute('data-index')].filters);
            this.$emit('chargeList');
        },
        callEntityList(e) {
            this.deletePhilter();
            volt.taks('.rapport-btn', '', false).forEach((btn) => {
                btn.style.backgroundColor = '';
            })
            e.target.style.backgroundColor = '#f3b61b';
            this.list_id = e.target.getAttribute('data-id');
            this.id_creator = e.target.getAttribute('data-creator');
            this.title = this.entity_list[e.target.getAttribute('data-index')].title;
            volt.listUser = this.entity_list[e.target.getAttribute('data-index')];
            if (this.user_list[e.target.getAttribute('data-index')].filters) volt.select_vals = volt.o(this.user_list[e.target.getAttribute('data-index')].filters);
            this.$emit('chargeList');
        },
        selectAutoList() {
            volt.taks('.rapport-btn', '', false).forEach((btn) => {
                btn.style.backgroundColor = '';
                if (btn.getAttribute('data-id') === volt.listUser._id) btn.style.backgroundColor = '#f3b61b';
            })
        },
        updateRapportFilters() {
            volt.listUser = {
                title: volt.tak('rapport-title').value,
                headers: volt.o(this.list.headers),
                type: this.rapport_type,
                user: this.user["_id"],
            };
            let data = {
                list: volt.listUser,
                id: this.list_id,
                year: this.year,
                title: volt.tak('rapport-title').value,
                type: this.rapport_type,
                filters: volt.select_vals
            }
            if (this.list_id !== '') {
                volt.post("obs/list/update", data, "", this.entity, this.year).then((response) => {
                    volt.listUser._id = response.id;
                    this.list_id = response.id;
                    setTimeout(() => {
                        this.$emit('callList', {_id: this.list_id});
                    }, 200);
                    // this.$emit('callList');
                }).catch((reason) => {
                    console.log(reason);
                    this.$emit('callList');
                })
            }
        }
    }
}
</script>

<style scoped>
.list-annonces {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 3px;
    grid-template-rows: repeat(30, 1fr);
    height: 98%;
    max-height: 98%;
    background-color: #fdfdfd;
    overflow: hidden;
    grid-column-start: v-bind(gridCS);
    grid-column-end: v-bind(gridCE);
    grid-row-start: v-bind(startList);
    grid-row-end: v-bind(gridRE);
}

.btn-header-div {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.title-list-annonces {
    grid-column-start: 1;
    grid-column-end: 9;
    text-align: start;
    padding: 8px;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.btn-list-annonces {
    grid-column-start: 9;
    grid-column-end: 21;
    display: flex;
    padding: 8px;
    flex-direction: row;
    justify-content: end;
}

.title-list {
    margin: 2px 5px 2px 0;
    padding: 10px;
    font-size: 20px;
}

.title-list-medium {
    font-size: 15px;
}

.header-list-annonces {
    margin-right: 10px;
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 2;
    grid-row-end: v-bind(headerEnd);
    border-bottom: solid 1px #94a3b2;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0px;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
}

.list-list-annonces {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: v-bind(startAnn);
    grid-row-end: v-bind(endList);
    border-top: solid 5px #94a3b2;
    border-image: linear-gradient(to top, #047cf4, #94a3b255) 1;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(28, 1fr);
    overflow: auto;
}

.map-list-annonces {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: v-bind(startAnn);
    grid-row-end: v-bind(endList);
    border: solid 1px #94a3b2;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(30, 1fr);
    overflow: auto;
    box-shadow: inset 1px 1px 5px #94a3b2;
}

.header-col-list-annonces {
    grid-row-start: 1;
    grid-row-end: 4;
}

.list-col-list-annonces {
    grid-row-start: 2;
    grid-row-end: 4;
    max-height: 100%;
    max-width: 100%;
    overflow-y: hidden;
}

.list-col-list-annonces:hover {
    height: max-content;
}

.header-col-text {
    display: flex;
    justify-content: center;
    position: relative;
    top: 30%;
    font-size: 15px;
    font-weight: 600;
}

.click-header {
    cursor: pointer;
}

.icon-filter-header {
    display: none;
    font-size: 12px;
    margin-left: 10px;
    color: #f3751b88;
}

.annonce-line {
    grid-column-start: 1;
    grid-column-end: 21;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(3, 1fr);
    height: 50px;
    cursor: pointer;
    border-bottom: solid 2px #7a7a7a15;
}

.annonce-line:hover {
    background-color: #e3e1e1;
    border-bottom: solid 2px #f3b61b55;
}

.annonce-line:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.footer-list-annonces {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: v-bind(startFooter);
    grid-row-end: 31;
    /*border: solid 1px #94a3b2;*/
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 2px;
    grid-template-rows: repeat(3, 1fr);
    margin-top: 10px;
}

.btn-list-last {
    grid-column-start: 8;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
    cursor: pointer;
    background-color: #eeeeee;
    border-radius: 10px;
}

.btn-list-last:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.btn-list-last-desactiv {
    display: none;
    grid-column-start: 6;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
    background-color: #f3cbcb;
    color: #f1a9a9;
}

.pagin-list-state {
    grid-column-start: 9;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 3;
    font-weight: bold;
}

.page-encour {
    position: relative;
    top: 25%;
}

.btn-list-next {
    grid-column-start: 12;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 4;
    cursor: pointer;
    background-color: #eeeeee;
    border-radius: 10px;
}

.btn-list-next-desactiv {
    display: none;
    grid-column-start: 14;
    grid-column-end: 15;
    grid-row-start: 1;
    grid-row-end: 4;
    background-color: #f3cbcb;
    color: #f1a9a9;
}

.btn-list-next:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.list-col-text {
    font-size: 13px;
}

.btn-option-list {
    border: none;
    cursor: pointer;
    margin: 2px 10px 2px 10px;
    padding: 10px;
    background-color: #eeeeee;
    border-radius: 5px;
    font-size: 20px;
}

.btn-option-list:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.btn-option-list-observatoire {
    border: none;
    cursor: pointer;
    margin: 0 2px 0 2px;
    padding: 5px;
    background-color: #eeeeee;
    border-radius: 5px;
}

.btn-option-list-observatoire:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.btn-option-list-chart {
    cursor: pointer;
    margin: 5px 15px 0 0;
    font-size: 20px;
    border: none;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 10px;
}

.btn-option-list-chart:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.btn-rapport-div {
    display: flex;

}

.rapport-btn {
    cursor: pointer;
    margin: 5px 15px 0 0;
    font-size: 17px;
    border: none;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 10px;
    width: 150px;
}

.rapport-btn:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.fa-times-circle {
    font-size: 20px;
    color: darkred;
}

.fa-compress-alt {
    font-size: 20px;
}

.fa-expand-alt {
    font-size: 20px;
}

#select-rapport-type {
    cursor: pointer;
    margin: 5px 15px 0 0;
    font-size: 18px;
    border: none;
    background-color: #eeeeee77;
    border-radius: 10px;
    padding: 10px;
}

.btn-help-rapport {
    cursor: pointer;
    margin: 5px 15px 0 0;
    font-size: 18px;
    border: none;
    background-color: #f3b61b;
    border-radius: 10px;
    padding: 10px;
}

@media (max-width: 1250px) {
    .btn-header-div {
        grid-column-start: 1;
        grid-column-end: 21;
        grid-row-start: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
    }

    .list-annonces {
        font-size: 10px;
        width: 200%;
        overflow: auto;
    }

    .list-col-text {
        font-size: 10px;
    }

    .header-col-text {
        font-size: 10px;
    }
}
</style>