<template>
    <div class="pop-search" :id="'pop-search-'+id">
        <div id="pop-search-content">
            <div class="pop-search-form-row">
                <input class="pop-search-input" :id="'pop-search-input-'+id" placeholder="Rechercher"/>
                <button class="btn-pop-search" @click="search"><i class="fas fa-search"></i></button>
            </div>
        </div>
    </div>
</template>

<script>

import volt from "@/volt";

export default {
    name: "PopSearch",
    components: {},
    emits: ["callSearch"],
    props: {
        id: String
    },
    methods: {
        search() {
            if (volt.tak('pop-search-input-' + this.id)){
                this.$emit('callSearch', {value: volt.tak('pop-search-input-' + this.id).value});
                volt.tak('pop-search-content').style.display = 'none';
                volt.tak('pop-search-'+this.id).style.height = '0px';
                volt.tak('pop-search-input-'+this.id).value = '';
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped>
.pop-search-input {
    width: 300px;
    font-size: 18px;
    outline: none;
    border: none;
    border-radius: 5px 0 0 5px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    background-color: #ededed;
    padding: 5px 10px 5px 10px;
}

.pop-search {
    position: fixed;
    top: 70px;
    left: 80%;
    width: 20%;
    background-color: #eceaea;
    z-index: 2500;
    border-left: solid 2px #f3b61b;
    padding-left: 15px;
    border-radius: 0 0 10px 10px;
    transition: 0.2s;
    height: 0;
    overflow: auto;

}

#pop-search-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 25px;
}

.pop-search-form-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
}
</style>