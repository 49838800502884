<template>
<!--    <div :id="'fullscreen-map-'+ title"  class="fullscreen-map"></div>-->
    <div class="map-ol" :id="'map-'+ title"></div>

</template>

<script>

export default {
  name: "MapsVue",
  props:{
    title : String,
    color : String,
    backgroundColor : String,
    gridCS : String,
    gridCE : String,
    gridRS : String,
    gridRE : String
  },
}
</script>

<style scoped>
.map-ol{
  grid-column-start: v-bind(gridCS);
  grid-column-end: v-bind(gridCE);
  grid-row-start: v-bind(gridRS);
  grid-row-end: v-bind(gridRE);
}

.map-ol:-webkit-full-screen {
    height: 400px;
    background-color: #b58408;
}

.map-ol:fullscreen {
    height: 400px;
    background-color: #b58408;
}
</style>