<template>
    <div v-if="Object.keys(annonce).length > 0" class="conformite-annonce" @click="closePopupWindews">
        <div class="header-top-div">
            <div class="header-top-value-elan">DONNEES ELAN</div>
            <div class="header-top-value-decla">DECLARATION</div>
            <div class="header-top-value-conformite">CONTROLES</div>
            <div class="header-top-value-comment">COMMENTAIRES</div>
        </div>
        <div class="header-lat-div">
            <div class="header-lat-value">Plateforme :</div>
            <div class="header-lat-value">Numéro d'enregistrement :</div>
            <div class="header-lat-value">Nom du loueur :</div>
            <div class="header-lat-value">Adresse du local :</div>
            <div class="header-lat-value">Statut résidence :</div>
            <div class="header-lat-value">Référence CU :</div>
            <div class="header-lat-value">Adresse CU :</div>
            <div class="header-lat-value">Déclarant CU :</div>
            <div class="header-lat-value">Statut CU :</div>
            <div class="header-lat-value">Date de validité :</div>
            <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Logements sociaux identifiés à cette adresse :</span>
            </div>
            <div class="header-lat-value"><span
                style="font-size: 12px;text-align: left">Nombre de jours de location :<br>(contrôle des 120 jours)</span>
            </div>
        </div>
        <div class="values-elan-div">
            <div class="values-elan-value no-border-right">{{ annonce.plateforme }}
                <img v-if="annonce.plateforme === 'Airbnb'" class="link-airbnb" src="airbnb_logo_icon_170606.png"
                     alt="logo airbnb" @click="openAirbnb"/>
                <img v-if="annonce.plateforme === 'Booking'" class="link-booking" src="Booking-Com-Logo.png"
                     alt="logo airbnb" @click="openBooking"/>
                <img v-if="annonce.plateforme === 'Abritel'" class="link-abritel" src="abritel-homeaway-logo.png"
                     alt="logo airbnb" @click="openAbritel"/>
            </div>
            <span style="display: none">{{ i = 1 }}</span>
            <div v-for="elan_value in elan_values" :key="'field_'+elan_value.value+'_'+i" class="values-elan-value"
                 :style="'background-color:'+color[elan_value.from]">
                <div v-if="elan_value.value !== ''" v-html="elan_value.value"></div>
                <div v-else class="empty-value">---</div>
                <span style="display: none">{{ i++ }}</span>
            </div>
        </div>
        <div class="values-decla-div">
            <div class="values-decla-value no-border-right"></div>
            <span style="display: none">{{ c = 1 }}</span>
            <div v-for="decla_value in decla_values" :key="'field_'+decla_value.value+'_'+c" class="values-decla-value"
                 :style="'background-color:'+color[decla_value.from]">
                <div v-if="decla_value.value !== ''" v-html="decla_value.value"></div>
                <div v-else class="empty-value">---</div>
                <span style="display: none">{{ c++ }}</span>
            </div>
        </div>
        <div class="values-conformite-div">
            <div class="values-conformite-value no-border-right" style="font-size: 10px;justify-content: end">
                <!--            <i v-if="historique_exist" :title="'Historique des mises à jour sur l\'année précédente ('+ ( year - 1 ) +')'" class="fa-solid fa-clock-rotate-left animation-icone" @click="callHistory"></i>-->
                <span v-if="!historique">(*) modifié par un agent</span>
                <span v-else style="font-size: 12px">Historique des mises à jour {{ year - 1 }}</span>
            </div>
            <span style="display: none">{{ c = 1 }}</span>
            <div v-for="conformite_value in conformites_value" :key="'field_'+conformite_value.value+'_'+c"
                 class="values-conformite-value">
                <SelectConformite v-if="conformite_value.field !== ''" :conformite="conformite_value" :annonces="ids"
                                  :annonce="annonce._id" :entity="entity" :user="user" :historique="historique"
                                  @changeValue="changeConform"/>
                <span style="display: none">{{ c++ }}</span>
            </div>
        </div>
        <div class="values-comment-div">
            <div class="values-comment-value" :style="'background-color:'+color_elan ">
                <i v-if="historique_exist"
                   :title="'Historique des mises à jour sur l\'année précédente ('+ ( year - 1 ) +')'"
                   class="fa-solid fa-circle-info animation-icone" @click="callHistory"></i>
                <!--          <span v-else style="font-size: 12px">Historique des mises à jour {{year-1}}</span>-->
            </div>
            <span style="display: none">{{ c = 1 }}</span>
            <div v-for="conformite_comm in conformites_comm" :key="'field_'+conformite_comm.value+'_'+c"
                 class="values-comment-value">
                <textarea v-if="conformite_comm.field !== '' && user.level != 3" rows="2" class="comment-textarea"
                          :id="conformite_comm.field" :value="conformite_comm.value"/>
                <textarea v-else-if="conformite_comm.field !== '' && user.level == 3" rows="2" class="comment-textarea"
                          :id="conformite_comm.field" :value="conformite_comm.value" disabled/>
                <div v-else> ---</div>
                <span style="display: none">{{ c++ }}</span>
            </div>
        </div>
        <div class="div-legend">
            <div class="div-legend-source">Sources :</div>
            <div class="div-legend-elan">ELAN</div>
            <div class="div-legend-ne">NE</div>
            <div class="div-legend-cu">CU</div>
            <div class="div-legend-traitement">Autre</div>
        </div>
        <div v-if="!historique && year > 2021 && user.level != 3" class="div-valid-values-comment">
            <button class="btn-valid-values-comment" @click="saveComm">Enregistrer</button>
        </div>
        <div v-if="historique && year > 2021 && user.level != 3" class="div-valid-values-comment-histo">
            <button class="btn-valid-values-comment" @click="saveHistory">Reporter sur {{ year }}</button>
        </div>
    </div>
    <div v-else-if="ids && ids.length > 0" class="conformite-annonce" @click="closePopupWindews">
        <div class="msg-alert-info">Attention Les modifications apportées ici impacteront les {{ ids.length }} annonces
            de la liste.
        </div>
        <div class="header-top-div-multi">
            <div class="header-top-value-conformite-multi">CONTROLES</div>
            <div class="header-top-value-comment-multi">COMMENTAIRES</div>
        </div>
        <div class="header-lat-div-multi">
            <div class="header-lat-value">Numéro d'enregistrement :</div>
            <div class="header-lat-value">Nom du loueur :</div>
            <div class="header-lat-value">Adresse du local :</div>
            <div class="header-lat-value">Statut résidence :</div>
            <div class="header-lat-value">Référence CU :</div>
            <div class="header-lat-value">Statut CU :</div>
            <div class="header-lat-value">Date de validité :</div>
            <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Logements sociaux identifiés à cette adresse :</span>
            </div>
            <div class="header-lat-value"><span
                style="font-size: 12px;text-align: left">Nombre de jours de location :<br>(contrôle des 120 jours)</span>
            </div>
        </div>
        <div v-if="year > 2021" class="values-conformite-div-multi">
            <span style="display: none">{{ c = 1 }}</span>
            <div v-for="conformite_value in conformites_value" :key="'field_'+conformite_value.value+'_'+c"
                 class="values-conformite-value">
                <SelectConformite :conformite="conformite_value" :annonces="ids" :annonce="annonce._id" :entity="entity"
                                  :user="user"/>
                <span style="display: none">{{ c++ }}</span>
            </div>
        </div>
        <div v-if="year > 2021" class="values-comment-div-multi">
            <span style="display: none">{{ c = 1 }}</span>
            <div v-for="conformite_comm in conformites_comm" :key="'field_'+conformite_comm.value+'_'+c"
                 class="values-comment-value">
                <textarea rows="2" class="comment-textarea" :id="conformite_comm.field+'_multi'"
                          :value="conformite_comm.value"/>
                <span style="display: none">{{ c++ }}</span>
            </div>
        </div>
        <div v-if="year > 2021 && user.level != 3" class="div-valid-values-comment-multi">
            <button class="btn-valid-values-comment" @click="saveComm">Enregistrer</button>
        </div>
    </div>
    <div v-else class="conformite-annonce-loader">
        <div>
            <KpiLoader title="pop-annonce"/>
        </div>
    </div>
</template>

<script>
import SelectConformite from "@/elements/SelectConformite";
import volt from "@/volt";
import KpiLoader from "@/components/KpiLoader";

export default {
    name: "ConformiteAnnonce",
    components: {KpiLoader, SelectConformite},
    emits: ['changeConfirmTest'],
    props: {
        annonce: Object,
        annonces: Object,
        entity: String,
        user: {},
        ids: Object,
        year: String
    },
    mounted() {
        volt.taks('.comment-textarea', '', false).forEach((el) => {
            el.addEventListener('change', () => {
                this.test_change = true;
                this.$emit('changeConfirmTest', {
                    test : this.test_change
                })
            })
        });
    },
    updated() {
        volt.taks('.comment-textarea', '', false).forEach((el) => {
            el.addEventListener('change', () => {
                this.test_change = true;
                this.$emit('changeConfirmTest', {
                    test : this.test_change
                })
            })
        });
    },
    methods: {
        openAirbnb() {
            let link = 'https://www.airbnb.fr/s/' + this.annonce.feature.properties.adr_elan.replace(/ /g, '-') + '--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&channel=EXPLORE&query=' + this.annonce.feature.properties.adr_elan + '%2C%20France&ne_lat=' + this.annonce.feature.geometry.coordinates[1] + '&ne_lng=' + this.annonce.feature.geometry.coordinates[0] + '&sw_lat=' + this.annonce.feature.geometry.coordinates[1] + '&sw_lng=' + this.annonce.feature.geometry.coordinates[0];
            window.open(link, '_blank');
        },
        openBooking() {
            let link = 'https://www.booking.com/searchresults.fr.html?nflt=distance%3D500&ss=' + this.annonce.feature.properties.adr_elan + '+France#map_opened'
            window.open(link, '_blank');
        },
        openAbritel() {
            let link = 'https://www.abritel.fr/search?destination=' + this.annonce.feature.properties.adr_elan + '%20France&latLong=' + this.annonce.feature.geometry.coordinates[1] + '%2C' + this.annonce.feature.geometry.coordinates[0] + '&mapBounds=' + (this.annonce.feature.geometry.coordinates[1] - 0.00001) + '%2C' + (this.annonce.feature.geometry.coordinates[0] - 0.00001) + '&mapBounds=' + (this.annonce.feature.geometry.coordinates[1] + 0.00001) + '%2C' + (this.annonce.feature.geometry.coordinates[0] + 0.00001) + '&sort=RECOMMENDED'
            window.open(link, '_blank');
        },
        closePopupConf() {
            volt.taks('.comment-textarea', '', false).forEach((el) => {
                el.style.height = '';
            });
            volt.taks('.value-select-field', '', false).forEach((el) => {
                el.style.border = '';
            });
            volt.taks('.select-choices-conform-field', '', false).forEach((el) => {
                el.style.display = '';
            });
            volt.taks('.fa-pen-alt', '', false).forEach((el) => {
                el.style.color = '';
            });
        },
        closePopupWindews(e) {
            if (!e.target.classList.contains('fa-pen-alt')) {
                this.closePopupConf();
            }
        },
        changeConform(annonce) {
            this.annonce_up = annonce.annonce;
            console.log(this.annonce_up)
            this.changeValue();
        },
        saveHistory() {
            let data = {
                fields: {},
                annonce: this.annonce._id,
                from: this.user
            }
            this.conformites_value.forEach((conf) => {
                if (conf.value !== conf.origin && conf.value !== '') {
                    data.fields[conf.field] = conf.value;
                }
            })
            this.conformites_comm.forEach((conf) => {
                if (conf.value !== conf.origin && conf.value !== '') {
                    data.fields[conf.field] = conf.value;
                }
            })
            volt.post('obs/history/update', data, '', this.entity).then((response) => {
                console.log(response);
                this.annonce_up = response.annonce;
                this.changeValue();
            });
        },
        saveComm() {
            let fields = {};
            if (this.ids.length > 0) {
                this.conformites_comm.forEach((comm) => {
                    if (volt.tak(comm.field + '_multi')) fields[comm.field] = volt.tak(comm.field + '_multi').value;
                })
            } else {
                this.conformites_comm.forEach((comm) => {
                    if (volt.tak(comm.field)) fields[comm.field] = volt.tak(comm.field).value;
                })
            }
            let data = {
                fields: fields,
                annonce: this.annonce._id,
                annonces: this.ids,
                from: this.user
            }
            if (data.annonces.length > 0) {
                if (confirm('Voulez-vous vraiment modifier les commentaires des ' + data.annonces.length + ' annonces ?')) {
                    volt.post('obs/comments/update', data, '', this.entity).then((response) => {
                        console.log(response);
                    });
                }
            } else {
                volt.post('obs/comments/update', data, '', this.entity).then((response) => {
                    this.annonce_up = response.annonce;
                    this.changeValue();
                });
            }
            this.test_change = false;
            this.$emit('changeConfirmTest', {
                test : this.test_change
            })
        },
        callHistory(e) {
            this.historique = !this.historique;
            if (this.historique) {
                e.target.style.animation = 'none';
                e.target.style.color = '#f3b61b';
            } else {
                e.target.style.animation = '';
                e.target.style.color = '';
            }
            this.changeValue();
        },
        changeValue() {
            if (this.year > 2021) {
                let up_conformite_ne = this.annonce_up.updates.up_conformite_ne;
                let details_up_conformite_ne = this.annonce_up.updates.details_up_conformite_ne;
                let up_conformite_nom_loueur = this.annonce_up.updates.up_conformite_nom_loueur;
                let details_up_conformite_nom_loueur = this.annonce_up.updates.details_up_conformite_nom_loueur;
                let up_conformite_adresse_loueur = this.annonce_up.updates.up_conformite_adresse_loueur;
                let details_up_conformite_adresse_loueur = this.annonce_up.updates.details_up_conformite_adresse_loueur;
                let up_conformite_statut_residence = this.annonce_up.updates.up_conformite_statut_residence;
                let details_up_conformite_statut_residence = this.annonce_up.updates.details_up_conformite_statut_residence;
                let up_conformite_cu = this.annonce_up.updates.up_conformite_cu;
                let details_up_conformite_cu = this.annonce_up.updates.details_up_conformite_cu;
                let up_conformite_statut_cu = this.annonce_up.updates.up_conformite_statut_cu;
                let details_up_conformite_statut_cu = this.annonce_up.updates.details_up_conformite_statut_cu;
                let up_conformite_date_validite_cu = this.annonce_up.updates.up_conformite_date_validite_cu;
                let details_up_conformite_date_validite_cu = this.annonce_up.updates.details_up_conformite_date_validite_cu;
                let up_conformite_log_soc = this.annonce_up.updates.up_conformite_log_soc;
                let details_up_conformite_log_soc = this.annonce_up.updates.details_up_conformite_log_soc;
                let up_conformite_rp_120j = this.annonce_up.updates.up_conformite_rp_120j;
                let details_up_conformite_rp_120j = this.annonce_up.updates.details_up_conformite_rp_120j;
                let com_conformite_ne = this.annonce_up.updates.com_conformite_ne;
                let details_com_conformite_ne = this.annonce_up.updates.details_com_conformite_ne;
                let com_conformite_nom_loueur = this.annonce_up.updates.com_conformite_nom_loueur;
                let details_com_conformite_nom_loueur = this.annonce_up.updates.details_com_conformite_nom_loueur;
                let com_conformite_adresse_loueur = this.annonce_up.updates.com_conformite_adresse_loueur;
                let details_com_conformite_adresse_loueur = this.annonce_up.updates.details_com_conformite_adresse_loueur;
                let com_conformite_statut_residence = this.annonce_up.updates.com_conformite_statut_residence;
                let details_com_conformite_statut_residence = this.annonce_up.updates.details_com_conformite_statut_residence;
                let com_conformite_cu = this.annonce_up.updates.com_conformite_cu;
                let details_com_conformite_cu = this.annonce_up.updates.details_com_conformite_cu;
                let com_conformite_statut_cu = this.annonce_up.updates.com_conformite_statut_cu;
                let details_com_conformite_statut_cu = this.annonce_up.updates.details_com_conformite_statut_cu;
                let com_conformite_date_validite_cu = this.annonce_up.updates.com_conformite_date_validite_cu;
                let details_com_conformite_date_validite_cu = this.annonce_up.updates.details_com_conformite_date_validite_cu;
                let com_conformite_log_soc = this.annonce_up.updates.com_conformite_log_soc;
                let details_com_conformite_log_soc = this.annonce_up.updates.details_com_conformite_log_soc;
                let com_conformite_rp_120j = this.annonce_up.updates.com_conformite_rp_120j;
                let details_com_conformite_rp_120j = this.annonce_up.updates.details_com_conformite_rp_120j;
                if (this.historique) {
                    up_conformite_ne = this.annonce_up.updates_n1.up_conformite_ne;
                    details_com_conformite_ne = this.annonce_up.updates_n1.details_up_conformite_ne;
                    up_conformite_nom_loueur = this.annonce_up.updates_n1.up_conformite_nom_loueur;
                    details_com_conformite_nom_loueur = this.annonce_up.updates_n1.details_up_conformite_nom_loueur;
                    up_conformite_adresse_loueur = this.annonce_up.updates_n1.up_conformite_adresse_loueur;
                    details_com_conformite_adresse_loueur = this.annonce_up.updates_n1.details_up_conformite_adresse_loueur;
                    up_conformite_statut_residence = this.annonce_up.updates_n1.up_conformite_statut_residence;
                    details_com_conformite_statut_residence = this.annonce_up.updates_n1.details_up_conformite_statut_residence;
                    up_conformite_cu = this.annonce_up.updates_n1.up_conformite_cu;
                    details_com_conformite_cu = this.annonce_up.updates_n1.details_up_conformite_cu;
                    up_conformite_statut_cu = this.annonce_up.updates_n1.up_conformite_statut_cu;
                    details_com_conformite_statut_cu = this.annonce_up.updates_n1.details_up_conformite_statut_cu;
                    up_conformite_date_validite_cu = this.annonce_up.updates_n1.up_conformite_date_validite_cu;
                    details_com_conformite_date_validite_cu = this.annonce_up.updates_n1.details_up_conformite_date_validite_cu;
                    up_conformite_log_soc = this.annonce_up.updates_n1.up_conformite_log_soc;
                    details_com_conformite_log_soc = this.annonce_up.updates_n1.details_up_conformite_log_soc;
                    up_conformite_rp_120j = this.annonce_up.updates_n1.up_conformite_rp_120j;
                    details_com_conformite_rp_120j = this.annonce_up.updates_n1.details_up_conformite_rp_120j;
                    com_conformite_ne = this.annonce_up.updates_n1.com_conformite_ne;
                    details_com_conformite_ne = this.annonce_up.updates_n1.details_com_conformite_ne;
                    com_conformite_nom_loueur = this.annonce_up.updates_n1.com_conformite_nom_loueur;
                    details_com_conformite_nom_loueur = this.annonce_up.updates_n1.details_com_conformite_nom_loueur;
                    com_conformite_adresse_loueur = this.annonce_up.updates_n1.com_conformite_adresse_loueur;
                    details_com_conformite_adresse_loueur = this.annonce_up.updates_n1.details_com_conformite_adresse_loueur;
                    com_conformite_statut_residence = this.annonce_up.updates_n1.com_conformite_statut_residence;
                    details_com_conformite_statut_residence = this.annonce_up.updates_n1.details_com_conformite_statut_residence;
                    com_conformite_cu = this.annonce_up.updates_n1.com_conformite_cu;
                    details_com_conformite_cu = this.annonce_up.updates_n1.details_com_conformite_cu;
                    com_conformite_statut_cu = this.annonce_up.updates_n1.com_conformite_statut_cu;
                    details_com_conformite_statut_cu = this.annonce_up.updates_n1.details_com_conformite_statut_cu;
                    com_conformite_date_validite_cu = this.annonce_up.updates_n1.com_conformite_date_validite_cu;
                    details_com_conformite_date_validite_cu = this.annonce_up.updates_n1.details_com_conformite_date_validite_cu;
                    com_conformite_log_soc = this.annonce_up.updates_n1.com_conformite_log_soc;
                    details_com_conformite_log_soc = this.annonce_up.updates_n1.details_com_conformite_log_soc;
                    com_conformite_rp_120j = this.annonce_up.updates_n1.com_conformite_rp_120j;
                    details_com_conformite_rp_120j = this.annonce_up.updates_n1.details_com_conformite_rp_120j;
                }
                this.conformites_value = [
                    {
                        field: 'up_conformite_ne',
                        origin: this.annonce.feature.properties.conformite_ne,
                        value: up_conformite_ne,
                        details: details_up_conformite_ne,
                    },
                    {
                        field: 'up_conformite_nom_loueur',
                        origin: this.annonce.feature.properties.conformite_nom_loueur,
                        value: up_conformite_nom_loueur,
                        details: details_up_conformite_nom_loueur,
                    },
                    {
                        field: 'up_conformite_adresse_loueur',
                        origin: this.annonce.feature.properties.conformite_adresse_loueur,
                        value: up_conformite_adresse_loueur,
                        details: details_up_conformite_adresse_loueur,
                    },
                    {
                        field: 'up_conformite_statut_residence',
                        origin: this.annonce.feature.properties.conformite_statut_residence,
                        value: up_conformite_statut_residence,
                        details: details_up_conformite_statut_residence,
                    },
                    {
                        field: 'up_conformite_cu',
                        origin: this.annonce.feature.properties.conformite_cu,
                        value: up_conformite_cu,
                        details: details_up_conformite_cu,
                    },
                    {
                        field: '',
                        origin: '',
                        value: '',
                        details: '',
                    },
                    {
                        field: '',
                        origin: '',
                        value: '',
                        details: '',
                    },
                    {
                        field: 'up_conformite_statut_cu',
                        origin: this.annonce.feature.properties.conformite_statut_cu,
                        value: up_conformite_statut_cu,
                        details: details_up_conformite_statut_cu,
                    },
                    {
                        field: 'up_conformite_date_validite_cu',
                        origin: this.annonce.feature.properties.conformite_date_validite_cu,
                        value: up_conformite_date_validite_cu,
                        details: details_up_conformite_date_validite_cu,
                    },
                    {
                        field: 'up_conformite_log_soc',
                        origin: this.annonce.feature.properties.conformite_log_soc,
                        value: up_conformite_log_soc,
                        details: details_up_conformite_log_soc,
                    },
                    {
                        field: 'up_conformite_rp_120j',
                        origin: this.annonce.feature.properties.conformite_rp_120j,
                        value: up_conformite_rp_120j,
                        details: details_up_conformite_rp_120j,
                    },
                ];
                this.conformites_comm = [
                    {
                        field: 'com_conformite_ne',
                        value: com_conformite_ne,
                        details: details_com_conformite_ne,
                    },
                    {
                        field: 'com_conformite_nom_loueur',
                        value: com_conformite_nom_loueur,
                        details: details_com_conformite_nom_loueur,
                    },
                    {
                        field: 'com_conformite_adresse_loueur',
                        value: com_conformite_adresse_loueur,
                        details: details_com_conformite_adresse_loueur,
                    },
                    {
                        field: 'com_conformite_statut_residence',
                        value: com_conformite_statut_residence,
                        details: details_com_conformite_statut_residence,
                    },
                    {
                        field: 'com_conformite_cu',
                        value: com_conformite_cu,
                        details: details_com_conformite_cu,
                    },
                    {
                        field: '',
                        value: '',
                        details: '',
                    },
                    {
                        field: '',
                        value: '',
                        details: '',
                    },
                    {
                        field: 'com_conformite_statut_cu',
                        value: com_conformite_statut_cu,
                        details: details_com_conformite_statut_cu,
                    },
                    {
                        field: 'com_conformite_date_validite_cu',
                        value: com_conformite_date_validite_cu,
                        details: details_com_conformite_date_validite_cu,
                    },
                    {
                        field: 'com_conformite_log_soc',
                        value: com_conformite_log_soc,
                        details: details_com_conformite_log_soc,
                    },
                    {
                        field: 'com_conformite_rp_120j',
                        value: com_conformite_rp_120j,
                        details: details_com_conformite_rp_120j,
                    },
                ]
            }
        }
    },
    data() {
        return {
            statut_residence: "Non communiqué",
            log_soc: "Non",
            elan_values: [],
            decla_values: [],
            annonce_up: {},
            conformites_value: [],
            conformites_comm: [],
            historique: false,
            historique_exist: false,
            color: {
                CU: 'rgba(177,248,203,0.33)',
                NE: 'rgba(178,198,243,0.33)',
                ELAN: 'rgba(227,192,244,0.33)',
                AUTRE: 'rgba(204,200,200,0.33)'
            },
            color_elan: 'rgba(227,192,244,0.33)',
            color_autre: 'rgba(204,200,200,0.33)',
            test_change: false
        }
    },
    watch: {
        historique() {

        },
        annonce() {
            this.annonce_up = this.annonce;
            this.historique = false;
            if (Object.keys(this.annonce).length > 0) {
                if (this.annonce.updates_n1.id_touriz !== undefined && this.annonce.updates_n1.id_touriz !== "Pas de correspondance") {
                    this.historique_exist = true;
                }
                this.elan_values = [];
                this.decla_values = [];
                if (this.annonce.feature.properties.res_ppale === 'oui' && (this.annonce.feature.properties.res_sec === 'non' || this.annonce.feature.properties.res_sec === '')) {
                    this.statut_residence = 'Résidence principale';
                } else if (this.annonce.feature.properties.res_sec === 'oui' && (this.annonce.feature.properties.res_ppale === 'non' || this.annonce.feature.properties.res_ppale === '')) {
                    this.statut_residence = 'Résidence secondaire';
                } else {
                    this.statut_residence = "Non communiqué";
                }
                if (this.annonce.feature.properties.log_soc === false) {
                    this.log_soc = 'Non';
                } else {
                    this.log_soc = 'Oui';
                }
                this.elan_values = [
                    {
                        value: this.annonce.feature.properties.id_num,
                        from: 'ELAN'
                    },
                    {
                        value: this.annonce.feature.properties.nom_loueur,
                        from: 'ELAN'
                    },
                    {
                        value: this.annonce.feature.properties.adr_elan,
                        from: 'ELAN'
                    },
                    {
                        value: this.annonce.feature.properties.statut_residence_elan ? this.annonce.feature.properties.statut_residence_elan : this.statut_residence,
                        from: 'ELAN'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: '',
                        from: 'AUTRE'
                    },
                    {
                        value: this.annonce.feature.properties.nuit_t1,
                        from: 'ELAN'
                    },
                ];
                this.decla_values = [
                    {
                        value: this.annonce.feature.properties.affichage_ne,
                        from: 'NE'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_nom_loueur,
                        from: 'NE'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_adresse_loueur,
                        from: 'NE'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_statut_residence,
                        from: 'NE'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_cu  +  (this.annonce.feature.properties.plusieurs_cu_possible > 1 ? ' <i class="fa-regular fa-copy" style="font-size: 15px;color: orange" title="Plusieurs dossiers en base pour l\'adresse ('+this.annonce.feature.properties.plusieurs_cu_possible+')"></i>' : ''),
                        from: 'CU'
                    },
                    {
                        value: this.annonce.feature.properties.adresse_cu,
                        from: 'CU'
                    },
                    {
                        value: this.annonce.feature.properties.nom_cu + (this.annonce.feature.properties.nbre_cu_declarant > 1 ? ' <i class="fa-regular fa-copy" style="font-size: 15px;color: orange" title="Plusieurs dossiers en base pour le déclarant ('+this.annonce.feature.properties.nbre_cu_declarant+')"></i>' : ''),
                        from: 'CU'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_statut_cu,
                        from: 'CU'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_date_validite_cu !== "" ? new Date(this.annonce.feature.properties.affichage_date_validite_cu).toLocaleDateString("fr") : '',
                        from: 'CU'
                    },
                    {
                        value: this.log_soc,
                        from: 'AUTRE'
                    },
                    {
                        value: this.annonce.feature.properties.affichage_rp_120j,
                        from: 'AUTRE'
                    },
                ];
                // if(this.year > 2021) {
                //     let up_conformite_ne = this.annonce.updates.up_conformite_ne;
                //     let up_conformite_nom_loueur = this.annonce.updates.up_conformite_nom_loueur;
                //     let up_conformite_adresse_loueur = this.annonce.updates.up_conformite_adresse_loueur;
                //     let up_conformite_statut_residence = this.annonce.updates.up_conformite_statut_residence;
                //     let up_conformite_cu = this.annonce.updates.up_conformite_cu;
                //     let up_conformite_statut_cu = this.annonce.updates.up_conformite_statut_cu;
                //     let up_conformite_date_validite_cu = this.annonce.updates.up_conformite_date_validite_cu;
                //     let up_conformite_log_soc = this.annonce.updates.up_conformite_log_soc;
                //     let up_conformite_rp_120j = this.annonce.updates.up_conformite_rp_120j;
                //     let com_conformite_ne = this.annonce.updates.com_conformite_ne;
                //     let com_conformite_nom_loueur = this.annonce.updates.com_conformite_nom_loueur;
                //     let com_conformite_adresse_loueur = this.annonce.updates.com_conformite_adresse_loueur;
                //     let com_conformite_statut_residence = this.annonce.updates.com_conformite_statut_residence;
                //     let com_conformite_cu = this.annonce.updates.com_conformite_cu;
                //     let com_conformite_statut_cu = this.annonce.updates.com_conformite_statut_cu;
                //     let com_conformite_date_validite_cu = this.annonce.updates.com_conformite_date_validite_cu;
                //     let com_conformite_log_soc = this.annonce.updates.com_conformite_log_soc;
                //     let com_conformite_rp_120j = this.annonce.updates.com_conformite_rp_120j;
                //     if(this.historique){
                //         up_conformite_ne = this.annonce.updates_n1.up_conformite_ne;
                //         up_conformite_nom_loueur = this.annonce.updates_n1.up_conformite_nom_loueur;
                //         up_conformite_adresse_loueur = this.annonce.updates_n1.up_conformite_adresse_loueur;
                //         up_conformite_statut_residence = this.annonce.updates_n1.up_conformite_statut_residence;
                //         up_conformite_cu = this.annonce.updates_n1.up_conformite_cu;
                //         up_conformite_statut_cu = this.annonce.updates_n1.up_conformite_statut_cu;
                //         up_conformite_date_validite_cu = this.annonce.updates_n1.up_conformite_date_validite_cu;
                //         up_conformite_log_soc = this.annonce.updates_n1.up_conformite_log_soc;
                //         up_conformite_rp_120j = this.annonce.updates_n1.up_conformite_rp_120j;
                //         com_conformite_ne = this.annonce.updates_n1.com_conformite_ne;
                //         com_conformite_nom_loueur = this.annonce.updates_n1.com_conformite_nom_loueur;
                //         com_conformite_adresse_loueur = this.annonce.updates_n1.com_conformite_adresse_loueur;
                //         com_conformite_statut_residence = this.annonce.updates_n1.com_conformite_statut_residence;
                //         com_conformite_cu = this.annonce.updates_n1.com_conformite_cu;
                //         com_conformite_statut_cu = this.annonce.updates_n1.com_conformite_statut_cu;
                //         com_conformite_date_validite_cu = this.annonce.updates_n1.com_conformite_date_validite_cu;
                //         com_conformite_log_soc = this.annonce.updates_n1.com_conformite_log_soc;
                //         com_conformite_rp_120j = this.annonce.updates_n1.com_conformite_rp_120j;
                //     }
                //   this.conformites_value = [
                //     {
                //       field: 'up_conformite_ne',
                //       origin: this.annonce.feature.properties.conformite_ne,
                //       value: up_conformite_ne,
                //       details: this.annonce.updates.details_up_conformite_ne,
                //     },
                //     {
                //       field: 'up_conformite_nom_loueur',
                //       origin: this.annonce.feature.properties.conformite_nom_loueur,
                //       value: up_conformite_nom_loueur,
                //       details: this.annonce.updates.details_up_conformite_nom_loueur,
                //     },
                //     {
                //       field: 'up_conformite_adresse_loueur',
                //       origin: this.annonce.feature.properties.conformite_adresse_loueur,
                //       value: up_conformite_adresse_loueur,
                //       details: this.annonce.updates.details_up_conformite_adresse_loueur,
                //     },
                //     {
                //       field: 'up_conformite_statut_residence',
                //       origin: this.annonce.feature.properties.conformite_statut_residence,
                //       value: up_conformite_statut_residence,
                //       details: this.annonce.updates.details_up_conformite_statut_residence,
                //     },
                //     {
                //       field: 'up_conformite_cu',
                //       origin: this.annonce.feature.properties.conformite_cu,
                //       value: up_conformite_cu,
                //       details: this.annonce.updates.details_up_conformite_cu,
                //     },
                //     {
                //       field: '',
                //       origin: '',
                //       value: '',
                //       details: '',
                //     },
                //     {
                //       field: '',
                //       origin: '',
                //       value: '',
                //       details: '',
                //     },
                //     {
                //       field: 'up_conformite_statut_cu',
                //       origin: this.annonce.feature.properties.conformite_statut_cu,
                //       value: up_conformite_statut_cu,
                //       details: this.annonce.updates.details_up_conformite_statut_cu,
                //     },
                //     {
                //       field: 'up_conformite_date_validite_cu',
                //       origin: this.annonce.feature.properties.conformite_date_validite_cu,
                //       value: up_conformite_date_validite_cu,
                //       details: this.annonce.updates.details_up_conformite_date_validite_cu,
                //     },
                //     {
                //       field: 'up_conformite_log_soc',
                //       origin: this.annonce.feature.properties.conformite_log_soc,
                //       value: up_conformite_log_soc,
                //       details: this.annonce.updates.details_up_conformite_log_soc,
                //     },
                //     {
                //       field: 'up_conformite_rp_120j',
                //       origin: this.annonce.feature.properties.conformite_rp_120j,
                //       value: up_conformite_rp_120j,
                //       details: this.annonce.updates.details_up_conformite_rp_120j,
                //     },
                //   ];
                //   this.conformites_comm = [
                //     {
                //       field: 'com_conformite_ne',
                //       value: com_conformite_ne,
                //       details: this.annonce.updates.details_com_conformite_ne,
                //     },
                //     {
                //       field: 'com_conformite_nom_loueur',
                //       value: com_conformite_nom_loueur,
                //       details: this.annonce.updates.details_com_conformite_nom_loueur,
                //     },
                //     {
                //       field: 'com_conformite_adresse_loueur',
                //       value: com_conformite_adresse_loueur,
                //       details: this.annonce.updates.details_com_conformite_adresse_loueur,
                //     },
                //     {
                //       field: 'com_conformite_statut_residence',
                //       value: com_conformite_statut_residence,
                //       details: this.annonce.updates.details_com_conformite_statut_residence,
                //     },
                //     {
                //       field: 'com_conformite_cu',
                //       value: com_conformite_cu,
                //       details: this.annonce.updates.details_com_conformite_cu,
                //     },
                //     {
                //       field: '',
                //       value: '',
                //       details: '',
                //     },
                //     {
                //       field: '',
                //       value: '',
                //       details: '',
                //     },
                //     {
                //       field: 'com_conformite_statut_cu',
                //       value: com_conformite_statut_cu,
                //       details: this.annonce.updates.details_com_conformite_statut_cu,
                //     },
                //     {
                //       field: 'com_conformite_date_validite_cu',
                //       value: com_conformite_date_validite_cu,
                //       details: this.annonce.updates.details_com_conformite_date_validite_cu,
                //     },
                //     {
                //       field: 'com_conformite_log_soc',
                //       value: com_conformite_log_soc,
                //       details: this.annonce.updates.details_com_conformite_log_soc,
                //     },
                //     {
                //       field: 'com_conformite_rp_120j',
                //       value: com_conformite_rp_120j,
                //       details: this.annonce.updates.details_com_conformite_rp_120j,
                //     },
                //   ]
                // }
                this.changeValue();
            }
        },
        annonces() {
            this.historique = false;
            if (this.annonces.length > 0) {
                this.conformites_value = [
                    {
                        field: 'up_conformite_ne',
                        value: '',
                    },
                    {
                        field: 'up_conformite_nom_loueur',
                        value: '',
                    },
                    {
                        field: 'up_conformite_adresse_loueur',
                        value: '',
                    },
                    {
                        field: 'up_conformite_statut_residence',
                        value: '',
                    },
                    {
                        field: 'up_conformite_cu',
                        value: '',
                    },
                    {
                        field: 'up_conformite_statut_cu',
                        value: '',
                    },
                    {
                        field: 'up_conformite_date_validite_cu',
                        value: '',
                    },
                    {
                        field: 'up_conformite_log_soc',
                        value: '',
                    },
                    {
                        field: 'up_conformite_rp_120j',
                        value: '',
                    },
                ];
                this.conformites_comm = [
                    {
                        field: 'com_conformite_ne',
                        value: '',
                    },
                    {
                        field: 'com_conformite_nom_loueur',
                        value: '',
                    },
                    {
                        field: 'com_conformite_adresse_loueur',
                        value: '',
                    },
                    {
                        field: 'com_conformite_statut_residence',
                        value: '',
                    },
                    {
                        field: 'com_conformite_cu',
                        value: '',
                    },
                    {
                        field: 'com_conformite_statut_cu',
                        value: '',
                    },
                    {
                        field: 'com_conformite_date_validite_cu',
                        value: '',
                    },
                    {
                        field: 'com_conformite_log_soc',
                        value: '',
                    },
                    {
                        field: 'com_conformite_rp_120j',
                        value: '',
                    },
                ]
            }
        }
    }
}
</script>

<style scoped>
.link-airbnb {
    width: 60px;
    cursor: pointer;
}

.link-booking {
    width: 60px;
    cursor: pointer;
}

.link-abritel {
    width: 60px;
    cursor: pointer;
}

.conformite-annonce {
    width: 98%;
    height: 98%;
    background-color: #eceaea;
    border-radius: 0 5px 0 0;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(15, 1fr);
    padding: 5px 1% 5px 1%;
    color: #666666;
}

.conformite-annonce-loader {
    width: 100%;
    height: 100%;
    background-color: #eceaea;
    /*background-color: #ed5858;*/
    border-radius: 0 5px 0 0;
    padding: 5px 1% 5px 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-top-div {
    grid-column-start: 5;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(1, 1fr);
}

.header-top-div-multi {
    grid-column-start: 8;
    grid-column-end: 21;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(1, 1fr);
}

.header-lat-div {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 15;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(12, 1fr);
}

.header-lat-div-multi {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 4;
    grid-row-end: 17;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(10, 1fr);
}

.header-lat-value {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    justify-content: start;
    border-bottom: solid 1px #7a7a7a55;
    background-color: v-bind(color_autre);
}

.header-top-value-elan {
    grid-column-start: 1;
    grid-column-end: 6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.values-elan-div {
    grid-column-start: 5;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 15;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(12, 1fr);
}

.values-elan-value {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    text-align: left;
    justify-content: space-between;
    border-bottom: solid 1px #7a7a7a55;
    border-right: solid 1px #7a7a7a55;
}

.header-top-value-decla {
    grid-column-start: 6;
    grid-column-end: 11;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.values-decla-div {
    grid-column-start: 10;
    grid-column-end: 15;
    grid-row-start: 2;
    grid-row-end: 15;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(12, 1fr);

}

.values-decla-value {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    text-align: left;
    justify-content: start;
    border-bottom: solid 1px #7a7a7a55;
    border-right: solid 1px #7a7a7a55;
}

.header-top-value-conformite {
    grid-column-start: 11;
    grid-column-end: 14;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.header-top-value-conformite-multi {
    grid-column-start: 1;
    grid-column-end: 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.values-conformite-div {
    grid-column-start: 15;
    grid-column-end: 18;
    grid-row-start: 2;
    grid-row-end: 15;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(12, 1fr);
}

.values-conformite-div-multi {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row-start: 4;
    grid-row-end: 17;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(10, 1fr);
}

.values-conformite-value {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding-left: 5px;
    border-bottom: solid 1px #7a7a7a55;
    border-right: solid 1px #7a7a7a55;
    background-color: v-bind(color_autre);
}

.header-top-value-comment {
    grid-column-start: 14;
    grid-column-end: 17;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.header-top-value-comment-multi {
    grid-column-start: 8;
    grid-column-end: 17;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    font-weight: 600;
    font-size: 15px;
}

.values-comment-div {
    grid-column-start: 18;
    grid-column-end: 21;
    grid-row-start: 2;
    grid-row-end: 15;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(12, 1fr);
    overflow: auto;
}

.values-comment-div-multi {
    grid-column-start: 13;
    grid-column-end: 21;
    grid-row-start: 4;
    grid-row-end: 17;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    grid-template-rows: repeat(10, 1fr);
    overflow: auto;
}

.div-valid-values-comment {
    padding: 10px 0 0 0;
    grid-column-start: 18;
    grid-column-end: 21;
    grid-row-start: 15;
    grid-row-end: 15;
}

.div-valid-values-comment-histo {
    padding: 10px 0 0 0;
    grid-column-start: 15;
    grid-column-end: 21;
    grid-row-start: 15;
    grid-row-end: 15;
}

.div-valid-values-comment-multi {
    padding: 10px 0 0 0;
    grid-column-start: 18;
    grid-column-end: 21;
    grid-row-start: 15;
    grid-row-end: 15;
}

.btn-valid-values-comment {
    padding: 5px;
    font-size: 15px;
    background-color: #f3b61b;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.btn-valid-values-comment:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.values-comment-value {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #7a7a7a55;
    border-right: solid 1px #7a7a7a55;
}

.no-border-right {
    border-right: none;
    background-color: v-bind(color_elan);
}

.empty-value {
    width: 100%;
    text-align: center;
}

.comment-textarea {
    border: none;
    padding: 5px;
    font-size: 10px;
    width: 100%;
    max-width: 100%;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    background-color: #efeeee;
    resize: none;
}

.comment-textarea:hover {
    resize: vertical;
}

.div-legend {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 15;
    grid-row-end: 15;
}

.div-legend-source {
    width: 100px;
    padding: 5px;
    text-align: right;
}

.div-legend-cu {
    width: 100px;
    padding: 5px;
    background-color: v-bind(color ['CU']);
}

.div-legend-ne {
    width: 100px;
    padding: 5px;
    background-color: v-bind(color ['NE']);
}

.div-legend-elan {
    width: 100px;
    padding: 5px;
    background-color: v-bind(color ['ELAN']);
}

.div-legend-traitement {
    width: 100px;
    padding: 5px;
    background-color: v-bind(color ['AUTRE']);
}

.msg-alert-info {
    grid-column-start: 2;
    grid-column-end: 20;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: #f3b61b;
    color: #ffffff;
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.fa-clock-rotate-left {
    font-size: 22px;
    margin-right: 20px;
    color: #7a0202;
    cursor: pointer;
}

.animation-icone {
    animation: breathing 3s ease-out infinite normal;
}

.fa-clock-rotate-left:hover {
    animation: rotating 2s linear infinite;
}

.fa-circle-info {
    font-size: 22px;
    margin-right: 20px;
    color: #7a0202;
    cursor: pointer;
}

.fa-circle-info:hover {
    animation: rotating 2s linear infinite;
}

@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
    }

    20% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
    }
    70% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
    }
}

@keyframes rotating {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
</style>