/* eslint-disable */
const volt = require("@/volt");

module.exports.call= function(name, year, size, offset, limit, order, by, entity, annonce = {}, q = '', facet = '' ,type = '', dest = '') {
    return new Promise((resolve,reject)=> {
        volt.getGlossaire().then((glossaire) => {
            this.getList(name, type).then((list) => {
                if (name === "all") name = "totalAnnonces";
                let cluster = '';
                if (name === 'clusterLoueur'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.cluster_multiloueur;
                    }else{
                        cluster = annonce;
                    }
                }
                if (name === 'clusterLoueurEPT'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.cluster_multiloueur_regroupement;
                    }else{
                        cluster = annonce;
                    }
                }
                else if (name === 'clusterAnnonce'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.cluster_multi_annonce;
                    }else{
                        cluster = annonce;
                    }
                }
                else if (name === 'clusterAdresse'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.cluster_adresse_multi_meubles;
                    }else{
                        cluster = annonce;
                    }
                }
                else if (name === 'clusterMorale'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.cluster_perso_morale_id;
                    }else{
                        cluster = annonce;
                    }
                }
                else if (name === 'clusterMoraleEPT'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.nom_cluster_personne_morale_regroupement;
                    }else{
                        cluster = annonce;
                    }
                }
                else if (name === 'clusterNE'){
                    if(annonce.feature){
                        cluster = annonce.feature.properties.id_num;
                    }else{
                        cluster = annonce;
                    }
                }else {
                    if(name.substring(0,7) !== 'cluster' && (by === undefined || by === ''))by = 'feature.properties.adr_elan';
                }
                if ((name === "user" && Object.keys(volt.select_vals).length > 0) || (name === "userPop" && Object.keys(volt.select_vals_pop).length > 0)) {
                    let selectVal = volt.select_vals;
                    if(name === "userPop")selectVal = volt.select_vals_pop;
                    let data_like = {};
                    if (Object.keys(volt.data_like).length > 0) {
                        data_like = volt.data_like
                    }
                    this.getFilterList(name, year, entity, size, offset, limit, order, by, cluster, selectVal,data_like).then((result) => {
                        let features = [];
                        if (result.list) {
                            features = result.list;
                            list.size = result.size;
                            list.ids = result.ids;
                        } else {
                            features = result;
                            list.size = size;
                        }
                        list.name = name;
                        list.offset = offset;
                        list.limit = limit;
                        list.order = order;
                        // list.title = result.list.title;
                        list.by = by;
                        let build = {};
                        if(features[0] && features[0]._id['cluster']){
                            build = this.clusterList(features, list, glossaire);
                            list.features = build.features;
                            list.layers = build.layers;
                        }else if(name === 'plateformTotalInt' || name === 'plateformRep' || name === 'plateformTotalIntV2' || name === 'vs_nbConciergeriesv2' || name === 'plateform_int_repV2' || name === 'evo_tauxRepConciergeries'){
                            build = this.plateformeList(features, list);
                            list.features = build.features;
                            list.layers = build.layers;
                        }else{
                            build = this.buildList(features, list, glossaire, name);
                            list.layers = this.filterConform(features)
                            list.features = features;
                        }
                        list.title = build.title;
                        list.type = build.type;
                        list.annonces = build.annonces;
                        list.headers = build.headers;
                        resolve(list);
                    }).catch((error) => {
                        console.log(error);
                        reject(error);
                    });
                } else {
                    let data_like = {};
                    if (Object.keys(volt.data_like).length > 0) {
                        data_like = volt.data_like
                    }
                    let selectVal = volt.select_vals;
                    if(dest === "popup")selectVal = volt.select_vals_pop;
                    this.getKpiList(name, year, entity, size, offset, limit, order, by, cluster, q, facet, data_like, selectVal).then((result) => {
                        let features = [];
                        if (result.list) {
                            features = result.list;
                            list.size = result.size;
                            list.ids = result.ids;
                        } else {
                            features = result;
                            list.size = size;
                        }
                        list.name = name;
                        list.offset = offset;
                        list.limit = limit;
                        list.order = order;
                        list.by = by;
                        list.q = q;
                        list.facet = facet;
                        let build = {};
                        if(features[0] && features[0]._id['cluster']){
                            build = this.clusterList(features, list, glossaire);
                            list.features = build.features;
                            list.layers = build.layers;
                        }else if(name === 'plateformTotalInt' || name === 'plateformRep' || name === 'plateformTotalIntV2' || name === 'vs_nbConciergeriesv2' || name === 'plateform_int_repV2' || name === 'evo_tauxRepConciergeries'){
                            build = this.plateformeList(features, list);
                            list.features = build.features;
                            list.layers = build.layers;
                        }else{
                            build = this.buildList(features, list, glossaire, name);
                            list.layers = this.filterConform(features)
                            list.features = features;
                        }
                        list.annonces = build.annonces;
                        list.headers = build.headers;
                        list.title = build.title;
                        list.type = build.type;
                        resolve(list);
                    }).catch((error) => {
                        console.log(error);
                        reject(error);
                    });
                }
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });;
    });
}
module.exports.plateformeList = function(features, list) {
    let annonces = [];
    let newFeatures = [];
    features.forEach((feature) => {
        let annonce = [];
        let newFeat = {_id : feature._id,  feature : { type: "feature" , properties: {}}};
        list.headers.forEach((header) => {
            let value = '';
            if (header.preField === undefined || header.preField === "" || header.preField === "not") {
                value = feature[header.field]
            }else{
                value = feature[header.preField][header.field];
            }
            annonce.push({
                color: '',
                value: value
            })
            newFeat.feature.properties[header.field] = value;
        });
        let ann = {annonce: {_id: feature._id, fields: annonce}};
        newFeatures.push(newFeat);
        annonces.push(ann);
    });
    let featColl = {
        conforme : {type : "FeatureCollection", features : []},
        a_verifier : {type : "FeatureCollection", features : []},
        non_conforme : {type : "FeatureCollection", features : []}
    };
    return {annonces : annonces, headers : list.headers, title : list.title, features : newFeatures, layers : featColl}
}
module.exports.clusterList = function(features, list, glossaire){
    let annonces = [];
    let newFeatures = [];
    features.forEach((feature) => {
        let annonce = [];
        let newFeat = {_id : feature._id['cluster'],  feature : { type: "feature" , properties: {}}};
        list.headers.forEach((header) => {
            let value = '';
            if (header.preField === undefined || header.preField === "" || header.preField === "not") {
                value = feature[header.field]
            }else{
                value = feature[header.preField][header.field];
            }
            annonce.push({
                color: '',
                value: value
            })
            newFeat.feature.properties[header.field] = value;
        });
        let ann = {annonce: {_id: feature._id, fields: annonce}};
        newFeatures.push(newFeat);
        annonces.push(ann);
    });
    let featColl = {
        conforme : {type : "FeatureCollection", features : []},
        a_verifier : {type : "FeatureCollection", features : []},
        non_conforme : {type : "FeatureCollection", features : []}
    };
    return {annonces : annonces, headers : list.headers, title : list.title, features : newFeatures, layers : featColl}
}
module.exports.buildList = function(features, list, glossaire, name){
    let annonces = [];
    features.forEach((feature) => {
        let annonce = [];
        if (name === 'user' && volt.listUser.headers !== undefined && volt.listUser.headers.length > 0) {
            if(volt.listUser.title !== undefined && volt.listUser.title !== '')list.title = volt.listUser.title;
            if(volt.listUser.type !== undefined && volt.listUser.type !== '')list.type = volt.listUser.type;
            list.headers = volt.listUser.headers;
        }
        list.headers.forEach((header) => {
            // if (header.preField === undefined || header.preField === "") {
                if(header.field === 'usage_conf'){
                    let conf_usage = this.usageConform(feature)
                    annonce.push({
                        color: conf_usage.color,
                        value: conf_usage.value
                    })
                }else{
                    annonce.push({
                        color: this.filterConformColor(feature, header.field, glossaire),
                        // value: feature.feature.properties[header.field]
                        value: this.boolValue(feature, glossaire, header)
                    })
                }
            // } else {
            //     annonce.push({
            //         color: this.filterConformColor(feature, header.field, glossaire),
            //         // value: feature[header.preField][header.field]
            //         value: this.boolValue(feature, glossaire, header)
            //     })
            // }
        });
        let ann = {annonce: {_id: feature._id, fields: annonce}};
        annonces.push(ann);
    })
    return {annonces : annonces, headers : list.headers, title : list.title, type : list.type}
}
module.exports.usageConform = function(feature){
    const colors = {
        "Conforme" : "",
        "A vérifier" : "orange",
        "Non conforme" : "red"
    }
    let value = '';
    let color = '';
    if(feature.feature.properties.affichage_cu !== 'Non concerné'){
        value = 'Conforme';
        if(feature.updates.up_conformite_cu === 'A vérifier' || feature.updates.up_conformite_statut_cu === 'A vérifier' || feature.updates.up_conformite_date_validite_cu === 'A vérifier'){
            value = 'A vérifier';
        }
        if(feature.updates.up_conformite_cu === 'Non conforme' || feature.updates.up_conformite_statut_cu === 'Non conforme' ||feature.updates.up_conformite_date_validite_cu === 'Non conforme'){
            value = 'Non conforme';
        }
        color = colors[value];
    }else{
       value = feature['feature']['properties']['affichage_cu'];
    }
    return {value: value, color: color};
}
module.exports.boolValue = function(feature, glossaire, header){
    let value = "";
    if (header.preField === undefined || header.preField === "" || header.preField === "not") {
        value = feature.feature.properties[header.field]
    }else{
        value = feature[header.preField][header.field];
    }
    if(glossaire['variables_api'][header.field]?.bool_value){
        if(value === '1' ||value === 1 || value === true || value === "true"){
            return "Oui";
        }else{
            return "Non";
        }
    }else{
        return value;
    }
}
module.exports.filterConformColor = function(annonce, field, glossaire){
    if(glossaire['variables_api'][field]) {
        const conform_field = glossaire['variables_api'][field].conform_field;
        const colors = {
            "Conforme": "",
            "A vérifier": "orange",
            "Non conforme": "red"
        }
        let color = "";
        if (conform_field !== '' && annonce.updates !== undefined) {
            return colors[annonce.updates['up_' + conform_field]];
        } else {
            return "";
        }
    }
}
module.exports.filterConform = function(features){
        let featTab = {
            conforme : [],
            non_conforme : [],
            a_verifier : []
        }
        const updates = [
            "up_conformite_adresse_loueur",
            "up_conformite_cu",
            "up_conformite_date_validite_cu",
            "up_conformite_log_soc",
            "up_conformite_ne",
            "up_conformite_nom_loueur",
            "up_conformite_rp_120j",
            "up_conformite_statut_cu",
            "up_conformite_statut_residence",
            "up_conformite_type_projet",
        ]
        features.forEach((feature)=>{
            let nonConfTest = false;
            let aVerifTest = false;
            if(feature.updates){
                updates.forEach((update, i)=>{
                    if(feature.updates[update] === "Non conforme"){
                        nonConfTest = true
                    }
                })
                if(!nonConfTest){
                    updates.forEach((update, i)=> {
                        if (feature.updates[update] === "A vérifier") {
                            aVerifTest = true
                        }
                    })
                    if(!aVerifTest){
                        featTab['conforme'].push(feature.feature)
                    }else{
                        featTab['a_verifier'].push(feature.feature)
                    }
                }else{
                    featTab['non_conforme'].push(feature.feature)
                }
            }else{
                featTab['conforme'].push(feature.feature)
            }
        })
        let featColl = {
            conforme : {type : "FeatureCollection", features : featTab['conforme']},
            a_verifier : {type : "FeatureCollection", features : featTab['a_verifier']},
            non_conforme : {type : "FeatureCollection", features : featTab['non_conforme']}
        };
        return(featColl);
}
module.exports.getList = function(name, type){
    return new Promise((resolve,reject)=>{
        const url = "/list.json";
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                resolve(data[name]);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })
}
module.exports.getKpis = function(){
    return new Promise((resolve,reject)=>{
        const url = "/kpi.json";
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                resolve(data["kpis"]);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })
}
module.exports.getComKpis = function(entity, year, specif = 'elan', specif_value){
    return new Promise((resolve, reject) => {
        volt.getEnv().then((env)=>{
            const url = env.paths.back+'kpis/';
            const sessionToken= sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity,
                'x-annee' : year
            };
            let spe = '';
            specif === 'elan' ? spe = '' : spe = specif ;
            const data = {
                // model : 'elan_2022'
                model : specif +'_'+ year,
                specif : spe,
                specif_value : specif_value
            };
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason)=>{
                    reject(reason);
                });
        });
    });
}
module.exports.getComKpi = function(title, entity, year, specif ='elan', specif_value = ''){
    return new Promise((resolve, reject) => {
        volt.getEnv().then((env)=>{
            const url = env.paths.back+'kpis/update/';
            const sessionToken= sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity,
                'x-annee' : year
            };
            let spe = '';
            specif === 'elan' ? spe = '' : spe = specif ;
            const data = {
                model : specif + '_' + year,
                title : title,
                specif : spe,
                specif_value : specif_value
            };
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason)=>{
                    reject(reason);
                });
        });
    });
}
module.exports.getKpiList = function(name, year, entity, size = 0, offset = 0, limit = 0, order = '', by = '', cluster = '', q = '' , facet = '', data_like = {}, data_select = {}){
    return new Promise((resolve, reject) => {
        volt.getEnv().then((env)=>{
            console.log(data_like)
            const url = env.paths.back+'obs/elan/kpi/find';
            const sessionToken= sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity,
                'x-annee' : year
            };
            const data = {
                by : by,
                order : order,
                limit : limit,
                offset : offset,
                list : name,
                type : name,
                cluster : cluster,
                q : q,
                facet : facet,
                data_like : data_like,
                verif_size : size === 0 ? '1' : '0',
                data_select : data_select
            };
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason)=>{
                    reject(reason);
                });
        });
    });
}
module.exports.getFilterList = function(name, year, entity, size = 0, offset = 0, limit = 0, order = '', by = '', cluster = '', filters= {}, data_like = {}){
    return new Promise((resolve, reject) => {
        volt.getEnv().then((env)=>{
            const url = env.paths.back+'obs/elan/find/filter';
            const sessionToken= sessionStorage.getItem('token');
            var myHeaders = {
                'Content-Type': 'application/json',
                'x-token': sessionToken,
                'x-login' : entity,
                'x-annee' : year
            };
            const data = {
                by : by,
                order : order,
                limit : limit,
                offset : offset,
                list : name,
                type : name,
                data_like : data_like,
                cluster : cluster,
                filters : filters,
                verif_size : size === 0 ? '1' : '0'
            };
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, myInit)
                .then(response => response.json())
                .then((response) => {
                    if (response.error_code === undefined) {
                        resolve(response);
                    }else{
                        reject(response)
                    }
                })
                .catch((reason)=>{
                    reject(reason);
                });
        });
    });
}
module.exports.getIrisPolygon = function(insee){
    return new Promise((resolve, reject) => {
    let url = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-france-iris&q=com_code='+insee+'&rows=-1';
    var myHeaders = {};
    var myInit = {
        method: 'GET',
        headers: myHeaders,
        cache: 'default'
    };
    fetch(url, myInit)
        .then(response => response.json())
        .then((response) => {
            if(response['records'] === undefined || response['records'].length === 0){
                resolve(false);
            }else{
                let features = [];
                response['records'].forEach((feature)=>{
                    let properties = feature.fields
                    let geometry = feature['fields']['geo_shape'];
                    delete properties.geo_shape;
                    features.push({
                        'type' : 'Feature',
                        'geometry' : geometry,
                        'properties' : properties
                    });
                });
                let geoFeature = {type : 'FeatureCollection', features : features};
                resolve(geoFeature);
            }
        })
        .catch((reason)=>{
            reject(reason);
        });
    });
}
module.exports.getInseeCenterPoint = function(insee){
    return new Promise((resolve, reject) => {
        let url = 'https://geo.api.gouv.fr/communes/'+insee+'?fields=centre&format=geojson&geometry=centre';
        var myHeaders = {};
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch((reason)=>{
                reject(reason);
            });
    });
}
module.exports.getInseePolygon = function(insee){
    return new Promise((resolve, reject) => {
        let url = 'https://geo.api.gouv.fr/communes/'+insee+'?fields=contour&format=geojson&geometry=contour';
        var myHeaders = {};
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch((reason)=>{
                reject(reason);
            });
    });
}

module.exports.geoloc = function(adresse){
    return new Promise((resolve,reject)=> {
        let url = 'https://api-adresse.data.gouv.fr/search/?q=' + adresse.replaceAll(' ', '+');
        var myHeaders = {};
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((response) => {
                resolve(response)
            }).catch((reason)=>{
                reject(reason)
        });
    });
}
module.exports.detailIris = function(feature_iris, entity, year , kpis_type = 'annonces'){
    return new Promise((resolve,reject)=>{
        volt.get(`obs/elan/find?kpis_type=${kpis_type}&q=annee,feature.properties.iris_code,feature.properties.code_insee&facet=`+year+','+feature_iris.properties.iris_code.substring(5)+','+feature_iris.properties.iris_code.substring(0,5), entity).then((annonces)=> {
            let center = {
                type : 'Feature',
                geometry : { type : 'Point' , coordinates: [feature_iris.properties.geo_point_2d[1], feature_iris.properties.geo_point_2d[0]]},
                properties : {}
            };
            let featColl = {type : "FeatureCollection", features : []};
            annonces.forEach((annonce)=>{
                annonce.feature.properties['_id'] = annonce._id;
                featColl.features.push(annonce.feature);
            })

            let datas = { iris : feature_iris, arrondissements : [] ,annonces : featColl, layers : this.filterConform(annonces) ,center : center }
            resolve(datas);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.detailArrondissement = function(feature, entity, year, kpis_type = 'annonces'){
    return new Promise((resolve,reject)=>{
        volt.get(`obs/elan/find?kpis_type=${kpis_type}&q=annee,feature.properties.code_insee&facet=`+year+','+feature.properties.code_insee, entity).then((annonces)=> {
            let center = {
                type : 'Feature',
                geometry : { type : 'Point' , coordinates: [feature.properties.geo_point_2d[0], feature.properties.geo_point_2d[1]]},
                properties : {}
            };
            let featColl = {type : "FeatureCollection", features : []};
            annonces.forEach((annonce)=>{
                annonce.feature.properties['_id'] = annonce._id;
                featColl.features.push(annonce.feature);
            })

            let datas = { iris : feature, arrondissements : [] ,annonces : featColl, layers : this.filterConform(annonces) ,center : center }
            resolve(datas);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.detailEntity = function(feature, entity, year, kpis_type = 'annonces'){
    return new Promise((resolve,reject)=>{
        volt.get(`obs/elan/find?kpis_type=${kpis_type}&q=annee,feature.properties.commune&facet=`+year+','+feature.properties.commune, entity).then((annonces)=> {
            let center = {
                type : 'Feature',
                geometry : { type : 'Point' , coordinates: [feature.properties.geo_point_2d[0], feature.properties.geo_point_2d[1]]},
                properties : {}
            };
            let featColl = {type : "FeatureCollection", features : []};
            annonces.forEach((annonce)=>{
                annonce.feature.properties['_id'] = annonce._id;
                featColl.features.push(annonce.feature);
            })

            let datas = { iris : feature, arrondissements : [] ,annonces : featColl, layers : this.filterConform(annonces) ,center : center }
            resolve(datas);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.irisInseeLog = function(code_insee, entity){
    return new Promise((resolve,reject)=>{
        volt.get('obs/iris/find?annee=2020&code_insee='+code_insee, entity).then((irisTab)=> {
            let irInseeLog = {};
            if(irisTab.length > 0){
                irisTab.forEach((iris)=>{
                    irInseeLog[iris.iris] = iris.logements;
                });
            }
            resolve(irInseeLog);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.irisInsee = function(code_insee, entity){
    return new Promise((resolve,reject)=>{
        volt.get('obs/iris/find?annee=2020&code_insee='+code_insee, entity).then((irisTab)=> {
            resolve(irisTab);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.irisInseeArr = function(code_insee, entity){
    return new Promise((resolve,reject)=>{
        volt.get('obs/iris/arr/find?annee=2020&code_insee='+code_insee, entity).then((irisTab)=> {
            resolve(irisTab);
        }).catch((reason)=>{
            reject(reason);
            console.log(reason);
        });
    });
}
module.exports.dispachIris = function(entity, year, load, kpis_type = 'annonces'){
    return new Promise((resolve,reject)=>{
        let path_name = '';
        if(kpis_type === 'annonces'){
            path_name = 'totalAnnonces'
        }else{
            path_name = 'totalLMT'
        }
        volt.get('obs/map/find?year='+year+'&kpis_type='+kpis_type, entity.label).then((map)=>{
            if(map[0] !== undefined && load !== "maj"){
                let datas = map[0].value;
                resolve(datas)
            }else{
                if(entity.type === "1") {
                    this.getInseePolygon(entity.city_code).then((polygon_entity) => {
                        this.irisInseeLog(entity.city_code, entity.label).then((irisLogement)=>{
                            this.getIrisPolygon(entity.city_code).then((iriss) => {
                                this.getKpiList(path_name, year, entity.label).then((annonces) => {
                                    let entity_name = entity.label;
                                    if(entity.label === 'ma commune'){
                                        entity_name = 'versailles';
                                    }
                                    this.geoloc(entity_name + ' ' + entity.post_code).then((geoloc) => {
                                        let datas = {iris: iriss, annonces: annonces.list, center: geoloc.features[0]}
                                        let iriss_top = {type: "FeatureCollection", features: []};
                                        let annonceCountsIris = {};
                                        let annonceCountsArr = {};
                                        let arrondissements = [];
                                        let arrondissements_top = {type: "FeatureCollection", features: []};
                                        let datasStock = {};
                                        let geoFeatArrondissement = {type: "FeatureCollection", features: []};
                                        annonces.list.forEach((annonce) => {
                                            annonceCountsIris[annonce.feature.properties.code_insee + annonce.feature.properties.iris_code] = (annonceCountsIris[annonce.feature.properties.code_insee + annonce.feature.properties.iris_code] || 0) + 1;
                                            annonceCountsArr[annonce.feature.properties.code_insee] = (annonceCountsArr[annonce.feature.properties.code_insee] || 0) + 1;
                                        });
                                        iriss.features.forEach((iris, i) => {
                                            iriss.features[i].properties.annonces_elan = annonceCountsIris[iris.properties.iris_code]; //ajout du nombre d'annonces par quartiers
                                            if (annonceCountsIris[iris.properties.iris_code] > 0 && annonces.size > 0) {
                                                iriss.features[i].properties.taux_annonces_elan = annonceCountsIris[iris.properties.iris_code] * 100 / annonces.size; //ajout du taux d'annonces par quartiers
                                                // if ((annonceCountsIris[iris.properties.iris_code] * 100 / annonces.size) > 1) {
                                                //     iriss_top.features.push(iriss.features[i]);
                                                // }
                                            } else {
                                                iriss.features[i].properties.taux_annonces_elan = 0;
                                            }
                                            if (annonceCountsIris[iris.properties.iris_code] > 0 && irisLogement[iris.properties.iris_code] > 0) {
                                                iriss.features[i].properties.taux_annonces_vs_logements = annonceCountsIris[iris.properties.iris_code] * 100 / irisLogement[iris.properties.iris_code];
                                                if ((annonceCountsIris[iris.properties.iris_code] * 100 / irisLogement[iris.properties.iris_code]) > 1) {
                                                    iriss_top.features.push(iriss.features[i]);
                                                }
                                            }
                                            arrondissements[iris.properties.com_arm_code] = iris.properties.com_arm_name;
                                        })
                                        if (entity.pref_arr !== '') {
                                            for (let key in arrondissements) {
                                                this.irisInseeArr(key, entity.label).then((irisLogementArr)=>{
                                                    let total_logements = 0;
                                                    irisLogementArr.forEach((iris)=>{
                                                        total_logements += parseInt(iris.logements);
                                                    });
                                                    this.getInseePolygon(key).then((polygon_arr) => {
                                                        this.getInseeCenterPoint(key).then((point_center) => {
                                                            let properties = {};
                                                            let coordinates = [];
                                                            let test_top = false;
                                                            let taux_annonce_arrond = 0;
                                                            properties['code_insee'] = key;
                                                            properties['arrondissement'] = arrondissements[key];
                                                            properties['arrondissement_rapport'] = 1;
                                                            properties['annonces_elan'] = 0;
                                                            properties['geo_point_2d'] = point_center.geometry.coordinates;
                                                            iriss.features.forEach((feature) => {
                                                                if (feature.properties.com_arm_code === key) {
                                                                    //compter les annonce_elan et les ajouter
                                                                    if (feature.properties.annonces_elan !== undefined) properties['annonces_elan'] += parseInt(feature.properties.annonces_elan);
                                                                }
                                                            });
                                                            if (properties['annonces_elan'] > 0 && annonces.size > 0) {
                                                                taux_annonce_arrond = properties['annonces_elan'] * 100 / annonces.size;
                                                                if(total_logements > 0){
                                                                    properties['taux_annonces_vs_logements_arr'] = properties['annonces_elan'] * 100 / total_logements;
                                                                    if (properties['taux_annonces_vs_logements_arr'] > 1) {
                                                                        test_top = true;
                                                                    }
                                                                }else{
                                                                    properties['taux_annonces_vs_logements_arr'] = 0;
                                                                }
                                                            }
                                                            properties['taux_annonces_arr'] = taux_annonce_arrond;
                                                            polygon_arr.properties = properties;
                                                            geoFeatArrondissement.features.push(polygon_arr);
                                                            if (test_top) {
                                                                arrondissements_top.features.push(polygon_arr)
                                                            }
                                                            if (geoFeatArrondissement.features.length === Object.keys(arrondissements).length) {
                                                                datas = {
                                                                    entity_polygon: polygon_entity,
                                                                    iris: iriss,
                                                                    arrondissements: geoFeatArrondissement,
                                                                    annonces: annonces.list,
                                                                    center: geoloc.features[0],
                                                                    iris_top: iriss_top,
                                                                    arrondissements_top: arrondissements_top,
                                                                    kpis_type: kpis_type
                                                                };
                                                                datasStock = {
                                                                    entity_polygon: polygon_entity,
                                                                    iris: iriss,
                                                                    arrondissements: geoFeatArrondissement,
                                                                    center: geoloc.features[0],
                                                                    iris_top: iriss_top,
                                                                    arrondissements_top: arrondissements_top,
                                                                    kpis_type: kpis_type
                                                                };
                                                                volt.post('obs/map/dispatch', {value: datasStock}, '', entity.label, year).then((response) => {
                                                                    console.log(response)
                                                                    resolve(datas)
                                                                }).catch((reason) => {
                                                                    console.log(reason);
                                                                    resolve(datas)
                                                                })
                                                            }
                                                        }).catch((reason) => {
                                                            console.log(reason)
                                                            reject(reason)
                                                        });
                                                    }).catch((reason) => {
                                                        console.log(reason)
                                                        reject(reason)
                                                    });
                                                }).catch((reason) => {
                                                    console.log(reason)
                                                    reject(reason)
                                                });
                                            }
                                        } else {
                                            datas = {
                                                entity_polygon: polygon_entity,
                                                iris: iriss,
                                                arrondissements: [],
                                                annonces: annonces.list,
                                                center: geoloc.features[0],
                                                iris_top: iriss_top,
                                                arrondissements_top: [],
                                                kpis_type: kpis_type
                                            }
                                            datasStock = {
                                                entity_polygon: polygon_entity,
                                                iris: iriss,
                                                arrondissements: [],
                                                center: geoloc.features[0],
                                                iris_top: iriss_top,
                                                arrondissements_top: [],
                                                kpis_type: kpis_type
                                            };
                                            volt.post('obs/map/dispatch', {value: datasStock}, '', entity.label, year).then((response) => {
                                                console.log(response)
                                                resolve(datas)
                                            }).catch((reason) => {
                                                console.log(reason);
                                                resolve(datas)
                                            })
                                        }
                                    }).catch((reason) => {
                                        console.log(reason)
                                        reject(reason)
                                    });
                                }).catch((reason) => {
                                    reject(reason)
                                })
                            }).catch((reason) => {
                                reject(reason)
                            });
                        }).catch((reason) => {
                            reject(reason)
                        });
                    }).catch((reason) => {
                        reject(reason)
                    });
                }else if(entity.type === "2"){
                    this.geoloc(entity.label + ' ' + entity.post_code).then((geoloc) => {
                        volt.get('entity/find/group', entity.label).then((entities)=>{
                        let datas = {entities: {type: "FeatureCollection", features: []}, center: geoloc.features[0]};
                        let total_annonces = 0;
                        let annonces_tab = [];
                        let polygon_group = [];
                        let datasStock = {};
                        entities.forEach((enti)=>{
                            this.irisInsee(enti.city_code, entity.label).then((irisLogement)=>{
                                let total_logements = 0;
                                irisLogement.forEach((iris)=>{
                                    total_logements += parseInt(iris.logements);
                                });
                                this.getInseePolygon(enti.city_code).then((polygon_entity) => {
                                    this.getInseeCenterPoint(enti.city_code).then((point_center) => {
                                        this.getKpiList(path_name, year, enti.entity).then((annonces, i) => {
                                            total_annonces += annonces.size;
                                            annonces_tab.push(annonces.list);
                                            polygon_group.push({type: "Feature", geometry: polygon_entity.geometry, properties: {}});
                                            polygon_entity.properties['annonces_elan'] = annonces.size;
                                            polygon_entity.properties['entity'] = enti;
                                            polygon_entity.properties['code_insee'] = enti.city_code;
                                            polygon_entity.properties['geo_point_2d'] = point_center.geometry.coordinates;
                                            polygon_entity.properties['ept_rapport'] = 1;
                                            datas.entities.features.push(polygon_entity);
                                            if(datas.entities.features.length === entities.length){
                                                let entities_top = {type: "FeatureCollection", features: []};
                                                datas.entities.features.forEach((ent, i) => {
                                                    datas.entities.features[i].properties.taux_annonces_elan = ent.properties.annonces_elan * 100 / total_annonces; //ajout du taux d'annonces par quartiers
                                                    if(total_logements > 0){
                                                        datas.entities.features[i].properties.taux_annonces_vs_logements = ent.properties.annonces_elan * 100 / total_logements;
                                                        if (datas.entities.features[i].properties.taux_annonces_vs_logements > 1) {
                                                            entities_top.features.push(datas.entities.features[i]);
                                                        }
                                                    }else{
                                                        datas.entities.features[i].properties.taux_annonces_vs_logements = 0;
                                                    }
                                                });
                                                datas = {
                                                    entity_polygon: {type: "FeatureCollection", features: polygon_group},//faire un multi_polygone avec toutes les entitées
                                                    annonces: annonces_tab,//cumuler les annonces de toutes les entitées
                                                    center: {type: "Feature", geometry: { type: "Point", coordinates : point_center.geometry.coordinates}, properties: {}}, //ok
                                                    entities_top: entities_top, //ok
                                                    entities: datas.entities, //ok
                                                    kpis_type: kpis_type
                                                }
                                                datasStock = {
                                                    entity_polygon: {type: "FeatureCollection", features: polygon_group},//faire un multi_polygone avec toutes les entitées
                                                    center: {type: "Feature", geometry: { type: "Point", coordinates : point_center.geometry.coordinates}, properties: {}}, //ok
                                                    entities_top: entities_top,
                                                    entities: datas.entities,
                                                    kpis_type: kpis_type
                                                };
                                                volt.post('obs/map/dispatch', {value: datasStock}, '', entity.label, year).then((response) => {
                                                    console.log(response)
                                                    resolve(datas)
                                                }).catch((reason) => {
                                                    console.log(reason);
                                                    reject(datas)
                                                })
                                            }
                                        }).catch((reason) => {
                                            reject(reason)
                                        })
                                    }).catch((reason) => {
                                        reject(reason)
                                    });
                                }).catch((reason) => {
                                    reject(reason)
                                });
                            }).catch((reason) => {
                                reject(reason)
                            });
                        })
                    //
                    //     // properties['ept_rapport'] = 1;
                        }).catch((reason) => {
                            console.log(reason)
                            reject(reason)
                        });
                    }).catch((reason) => {
                        console.log(reason)
                        reject(reason)
                    });
                }
            }
        }).catch((reason)=>{
            console.log(reason)
            reject(reason)
        });
    })
}