<template>
    <div class="pop-select" :id="'pop-select-'+id">
        <div :id="'pop-select-content-'+id" class="pop-select-content">
            <div class="pop-select-header">
                <div class="pop-select-header-options">
                    <div v-if="glossaire[entete] && glossaire[entete]['sort']" class="sort-pop-select"
                         :id="'icon-sort-max-pop-'+entete" data-order="-1" @click="sortList"><i data-order="-1"
                                                                                                class="fa-solid fa-arrow-down-wide-short"></i>
                    </div>
                    <div v-if="glossaire[entete] && glossaire[entete]['sort']" class="sort-pop-select"
                         :id="'icon-sort-min-pop-'+entete" data-order="1" @click="sortList"><i data-order="1"
                                                                                               class="fa-solid fa-arrow-down-short-wide"></i>
                    </div>
                </div>
                <div @click="closePopup" class="close-pop-select"><i class="far fa-window-close"></i></div>
            </div>
            <div v-if="glossaire[entete] && glossaire[entete]['search'] && id !== 'popup'" class="pop-search-input"><input class="pop-select-input" :id="'pop-select-input-'+id"/>
                <button class="btn-pop-search" @click="searchValuesLike"><i class="fas fa-search"></i></button>
            </div>
            <div v-if="values.length > 0" class="pop-select-list">
                <div class="pop-choice global-choice-all" data-value="tout" @click="pushValue">Tout</div>
                <div v-for="choice in values" :key="choice">
                    <div v-if="choice === ''" class="pop-choice global-choice-empty" data-value="vide"
                         @click="pushValue">Vide
                    </div>
                    <div v-else-if="choice === 'true' || choice === true" class="pop-choice" :data-value="choice"
                         @click="pushValue">Oui
                    </div>
                    <div v-else-if="choice === 'false' || choice === false" class="pop-choice" :data-value="choice"
                         @click="pushValue">Non
                    </div>
                    <div v-else class="pop-choice" :data-value="choice" @click="pushValue">{{ choice }}</div>
                </div>
            </div>
            <div v-else>
                <div class="pop-choice-loader">
                    <LoaderParams/>
                </div>
            </div>
<!--            <div v-if="select_vals[entete] !== undefined && select_vals[entete].values.length > 0"-->
            <div v-if="select_vals_tmp[entete] !== undefined && select_vals_tmp[entete].values.length > 0"
                 class="title-pop-select">
                Sélection
                <div>
                    <span v-if="id !== 'popup'" class="global-delete-all" title="Ajouter" @click="addValue" id="add-select-vals-bool"
                          :data-value="add_select_vals"><i class="fas fa-plus-circle"></i></span>
                    <span class="global-delete-all" title="Tout supprimer" data-value="tout" @click="deleteAllValue"><i
                        class="fas fa-trash"></i></span>
                </div>
            </div>
<!--            <div v-if="select_vals[entete] !== undefined && select_vals[entete].values.length > 0"-->
            <div v-if="select_vals_tmp[entete] !== undefined && select_vals_tmp[entete].values.length > 0"
                 class="pop-select-val-list">
                <span style="display: none">{{ i = 0 }}</span>
<!--                <div v-for="select_val in select_vals[entete].values" :key="select_val">-->
                <div v-for="select_val in select_vals_tmp[entete].values" :key="select_val">
                    <div v-if="select_val === ''" class="pop-choice-select-val global-choice-empty" :data-index="i"
                         @click="deleteValue" title="Supprimer">Vide<i class="fas fa-minus-circle" :data-index="i"></i>
                    </div>
                    <div v-else-if="select_val === 'true' || select_val === true" class="pop-choice-select-val"
                         :data-index="i" @click="deleteValue" title="Supprimer"> Oui<i class="fas fa-minus-circle"
                                                                                       :data-index="i"></i></div>
                    <div v-else-if="select_val === 'false' || select_val === false" class="pop-choice-select-val"
                         :data-index="i" @click="deleteValue" title="Supprimer"> Non<i class="fas fa-minus-circle"
                                                                                       :data-index="i"></i></div>
                    <div v-else class="pop-choice-select-val" :data-index="i" @click="deleteValue" title="Supprimer">
                        {{ select_val }}<i class="fas fa-minus-circle" :data-index="i"></i></div>
                    <!--          <div v-else class="pop-choice-select-val global-choice-empty" :data-index="i" @click="deleteValue" title="Supprimer">Vide<i class="fas fa-minus-circle" :data-index="i"></i></div>-->
                    <span style="display: none">{{ i++ }}</span>
                </div>
            </div>
<!--            <div v-if="select_vals[entete] !== undefined && select_vals[entete].values.length > 0"-->
            <div v-if="select_vals_tmp[entete] !== undefined && select_vals_tmp[entete].values.length > 0"
                 class="valid-filter-div">
                <div class="valid-filter-btn" @click="validFilter">Filtrer</div>
            </div>
        </div>
    </div>
</template>

<script>
import volt from "@/volt";
import LoaderParams from "@/components/LoaderParams";

export default {
    name: "PopSelect",
    emits: ['searchLike', 'callList', 'callListFilter'],
    components: {LoaderParams},
    props: {
        id: String,
        values: Object,
        entete: String,
        preField: String,
        name: String,
        list: Object,
        glossaire: Object
    },
    data() {
        return {
            listChoices: [],
            select_vals: {},
            select_vals_tmp: {},
            select_vals_pop: {},
            sort_vals: {},
            sort_vals_tmp: {},
            sort_vals_pop: {},
            add_select_vals: "0"
        }
    },
    watch: {
        values() {
            // this.add_select_vals = "0";
        }
    },
    mounted() {
        this.select_vals = volt.select_vals;
        this.select_vals_pop = volt.select_vals_pop;
        if(this.id === 'popup'){
            this.select_vals_tmp = this.select_vals_pop;
        }else{
            this.select_vals_tmp = this.select_vals;
        }
    },
    updated() {
        this.select_vals = volt.select_vals;
        this.select_vals_pop = volt.select_vals_pop;
        if(this.id === 'popup'){
            this.select_vals_tmp = this.select_vals_pop;
        }else{
            this.select_vals_tmp = this.select_vals;
        }
    },
    methods: {
        sortList(e) {
            // this.sort_vals = {};
            this.sort_vals_tmp = {};
            e.target.style.color = '#f3b61b';
            let pre_field = "feature.properties";
            if (this.preField !== null && this.preField !== '' && this.preField !== 'not') {
                pre_field = this.preField;
            } else if (this.preField === 'not') {
                pre_field = 'not';
            }
            // this.sort_vals[this.entete] = {
            this.sort_vals_tmp[this.entete] = {
                field: this.entete,
                prefield: pre_field === 'not' ? '' : pre_field,
                order: e.target.getAttribute('data-order')
            }
            // volt.sort_vals = volt.o(this.sort_vals);

            if(this.id !== 'popup') {
                this.sort_vals = this.sort_vals_tmp;
                volt.sort_vals = volt.o(this.sort_vals_tmp);
            }else {
                this.sort_vals_pop = this.sort_vals_tmp;
                volt.sort_vals_pop = volt.o(this.sort_vals_tmp);
            }
            this.closePopup();
            this.$emit('callList', this.list.name);
        },
        closePopup() {
            volt.taks('.header-col-text', '', false).forEach((el) => {
                el.style.color = ''
            })
            volt.tak('pop-select-'+this.id).style.height = '';
            volt.tak('pop-select-content-'+this.id).style.display = '';
            if (volt.tak('pop-select-input-'+this.id)) volt.tak('pop-select-input-'+this.id).value = '';
        },
        searchValuesLike() {
            setTimeout(() => {
                this.$emit('searchLike')
            }, 100);
        },
        pushValue(e) {
            // if (this.select_vals[this.entete] === undefined) {
            //     let val = [];
            //     if (e.target.getAttribute('data-value') === 'tout') {
            //         val = volt.o(this.values);
            //     } else if (e.target.getAttribute('data-value') === 'vide') {
            //         val = [''];
            //     } else if (e.target.getAttribute('data-value') === 'false') {
            //         val = [false];
            //     } else if (e.target.getAttribute('data-value') === 'true') {
            //         val = [true];
            //     } else {
            //         val = [e.target.getAttribute('data-value')]
            //     }
            //     let pre_field = "feature.properties";
            //     if (this.preField !== null && this.preField !== '' && this.preField !== 'not') {
            //         pre_field = this.preField;
            //     }
            //     if (this.preField === 'not') {
            //         pre_field = 'not';
            //     }
            //     this.select_vals[this.entete] = {
            //         field: this.entete,
            //         prefield: pre_field === 'not' ? '' : pre_field,
            //         values: val
            //     }
            //     volt.tak('icon-filter-header-' + this.entete).style.display = 'block';
            // } else {
            //     if (e.target.getAttribute('data-value') === 'tout') {
            //         this.select_vals[this.entete].values = volt.o(this.values);
            //     } else if (e.target.getAttribute('data-value') === 'vide') {
            //         this.select_vals[this.entete].values.push('');
            //     } else if (e.target.getAttribute('data-value') === 'false') {
            //         this.select_vals[this.entete].values.push(false);
            //     } else if (e.target.getAttribute('data-value') === 'true') {
            //         this.select_vals[this.entete].values.push(true);
            //     } else {
            //         this.select_vals[this.entete].values.push(e.target.getAttribute('data-value'))
            //     }
            // }
            if (this.select_vals_tmp[this.entete] === undefined) {
                let val = [];
                if (e.target.getAttribute('data-value') === 'tout') {
                    val = volt.o(this.values);
                } else if (e.target.getAttribute('data-value') === 'vide') {
                    val = [''];
                } else if (e.target.getAttribute('data-value') === 'false') {
                    val = [false];
                } else if (e.target.getAttribute('data-value') === 'true') {
                    val = [true];
                } else {
                    val = [e.target.getAttribute('data-value')]
                }
                let pre_field = "feature.properties";
                if (this.preField !== null && this.preField !== '' && this.preField !== 'not') {
                    pre_field = this.preField;
                }
                if (this.preField === 'not') {
                    pre_field = 'not';
                }
                this.select_vals_tmp[this.entete] = {
                    field: this.entete,
                    prefield: pre_field === 'not' ? '' : pre_field,
                    values: val
                }
                volt.tak('icon-filter-header-'+this.id+'-' + this.entete).style.display = 'block';
            } else {
                if (e.target.getAttribute('data-value') === 'tout') {
                    this.select_vals_tmp[this.entete].values = volt.o(this.values);
                } else if (e.target.getAttribute('data-value') === 'vide') {
                    this.select_vals_tmp[this.entete].values.push('');
                } else if (e.target.getAttribute('data-value') === 'false') {
                    this.select_vals_tmp[this.entete].values.push(false);
                } else if (e.target.getAttribute('data-value') === 'true') {
                    this.select_vals_tmp[this.entete].values.push(true);
                } else {
                    this.select_vals_tmp[this.entete].values.push(e.target.getAttribute('data-value'))
                }
            }
            if(this.id !== 'popup') {
                this.select_vals = this.select_vals_tmp;
            }else {
                this.select_vals_pop = this.select_vals_tmp;
            }
        },
        deleteValue(e) {
            // this.select_vals[this.entete].values.splice(e.target.getAttribute('data-index'), 1);
            // if (this.select_vals[this.entete].values.length === 0) {
            //     delete this.select_vals[this.entete];
            //     volt.tak('icon-filter-header-' + this.entete).style.display = '';
            // }
            this.select_vals_tmp[this.entete].values.splice(e.target.getAttribute('data-index'), 1);
            if (this.select_vals_tmp[this.entete].values.length === 0) {
                delete this.select_vals_tmp[this.entete];
                volt.tak('icon-filter-header-' +this.id+'-' + this.entete).style.display = '';
            }
            if(this.id !== 'popup') {
                this.select_vals = this.select_vals_tmp;
            }else {
                this.select_vals_pop = this.select_vals_tmp;
            }
        },
        deleteAllValue() {
            // delete this.select_vals[this.entete];
            // volt.tak('icon-filter-header-' + this.entete).style.display = '';
            // volt.select_vals = volt.o(this.select_vals);

            delete this.select_vals_tmp[this.entete];
            volt.tak('icon-filter-header-'+this.id+'-'  + this.entete).style.display = '';

            if(this.id !== 'popup') {
                this.select_vals = this.select_vals_tmp;
                volt.select_vals = volt.o(this.select_vals_tmp);
            }else {
                this.select_vals_pop = this.select_vals_tmp;
                volt.select_vals_pop = volt.o(this.select_vals_tmp);
            }
            this.closePopup();
            this.$emit('callList');
        },
        validFilter() {
            if(this.id !== 'popup'){
                volt.select_vals = volt.o(this.select_vals);
                this.closePopup();
                this.$emit('callList', this.list.name);
            }else{
                volt.select_vals_pop = volt.o(this.select_vals_pop);
                this.closePopup();
                this.$emit('callList', this.list.name);
            }
        },
        addValue() {
            this.add_select_vals = "1";
            setTimeout(() => {
                this.$emit('searchLike')
            }, 500);
        }
    }
}
</script>

<style scoped>
.pop-select {
    width: 350px;
    height: 0;
    box-shadow: 1px 1px 5px #7a7a7a;
    background-color: #e7e7e7;
    border-radius: 10px;
    position: fixed;
    top: 21%;
    left: 40%;
    z-index: 1000;
    transition: 0.4s;
}

.pop-select-content {
    display: none;
    padding: 10px;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    height: 98%;
}

.pop-select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 330px;
}

.pop-select-header-options {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100px;
}

.close-pop-select {
    cursor: pointer;
}

.sort-pop-select {
    cursor: pointer;
    font-size: 15px;
    margin-right: 10px;
    padding: 3px;
}

.sort-pop-select:active {
    font-size: 14px;
    margin-right: 11px;
    box-shadow: inset 1px 1px 5px #a0a0a0;
}

.pop-select-input {
    width: 290px;
    /*margin: 10px;*/
    font-size: 15px;
    outline: none;
    border: none;
    border-radius: 5px 0 0 5px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    background-color: #ededed;
    padding: 5px 10px 5px 10px;
}

.pop-select-list {
    width: 290px;
    height: 85%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    padding: 5px 10px 5px 10px;
    overflow: auto;
}

.pop-select-val-list {
    width: 290px;
    min-height: 20%;
    max-height: 50%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    padding: 5px 10px 5px 10px;
    overflow: auto;
}

.pop-choice {
    /*margin: 5px;*/
    font-size: 12px;
    border-bottom: solid 1px #7a7a7a33;
    padding: 3px 5px 3px 5px;
    cursor: pointer;
    text-align: start;
}

.pop-choice:hover {
    background-color: #f3b61b55;
}

.pop-choice:active {
    box-shadow: inset 1px 1px 5px #a0a0a0;
}

.pop-choice-select-val {
    font-size: 12px;
    border-bottom: solid 1px #7a7a7a33;
    padding: 3px 5px 3px 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.pop-choice-select-val:hover {
    background-color: rgba(243, 27, 85, 0.33);
}

.pop-choice-select-val:active {
    box-shadow: inset 1px 1px 5px #a0a0a0;
}

.pop-choice-loader {
    width: 290px;
    height: 100%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: inset 1px 1px 5px #7a7a7a;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-window-close {
    font-size: 18px;
    padding: 3px;
    border-radius: 3px;
}

.fa-window-close:active {
    box-shadow: inset 1px 1px 5px #a0a0a0;
}

.pop-search-input {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 310px;
    margin: 10px;
    font-size: 18px;
}

.btn-pop-search {
    width: 80px;
    border: solid 1px #7a7a7a55;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    background-color: #e3e2e2;
}

.btn-pop-search:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.title-pop-select {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: normal;
    margin-left: 10px;
    width: 60%;
    border-bottom: solid 1px rgba(243, 27, 85, 0.33);
}

.global-choice-empty {
    color: #f3751b;
}

.global-choice-all {
    color: #0e7f65;
}

.global-delete-all {
    font-size: 15px;
    text-align: center;
    margin: 0;
    margin-left: 5px;
    padding: 2px;
    cursor: pointer;
    width: 20px;
}

.global-delete-all:hover {
    color: rgba(243, 27, 85, 0.33);
}

.global-delete-all:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.valid-filter-div {
    display: flex;
    justify-content: flex-end;
    font-size: 15px;
    font-weight: normal;
    width: 90%;
    padding: 5px;
}

.valid-filter-btn {
    cursor: pointer;
    outline: solid 1px #7a7a7a;
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    background-color: #42b98377;
}

.valid-filter-btn:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.fa-minus-circle {
    color: darkred;
}

.fa-plus-circle {
    color: darkcyan;
}

.fa-trash {
    color: darkred;
}
</style>