<template>
    <div class="menu-float" :id="'menu-float-'+title">
        <div class="menu-float-top-bar" :id="'top-bar-menu-float-'+title" :data-id="'menu-float-'+title"
             @mousedown="move" @mouseup="stopMove">
            {{ title }}
        </div>
        <div class="closed-menu-float" :data-id="'menu-float-'+title" @click="closeFloatMenu"><i
            class="fas fa-times-circle" :data-id="'menu-float-'+title" @click="closeFloatMenu"
            :style="'color:'+color"></i></div>
        <!--    <div class="closed-menu-float" :data-id="title" @click="reduceFloatMenu"><i class="fas fa-minus" :data-id="title" :id="'icon-reduce-menu-float-content-'+title" :style="'color:'+color"></i></div>-->
        <div class="menu-float-content" :id="'menu-float-content-'+title">
            <div class="detail-content-floatmenu" :id="'detail-content-' + title">
                <div class="outils-contant-float" :id="'outils-content-'+ title">
                    <MapsVue
                        title='CU'
                        gridCS="1"
                        gridCE="21"
                        gridRS="1"
                        gridRE="6"
                    />
                    <div class="title-pop-form"><h3>{{title}}</h3></div>
                    <div class="form-CU-div">
                        <div v-for="(el, index) in dossier" :key="el" class="form-row"><InputForm :label="index" :value="el" :disabled="true"/></div>
                    </div>
                    <div class="form-CU-div2">
                        <div v-for="(el, index) in tier" :key="el" class="form-row"><InputForm :label="index" :value="el" :disabled="true"/></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import volt from "@/volt";
import MapsVue from "@/components/MapsVue";
import InputForm from "@/components/InputForm.vue";

export default {
    name: "PopDossierCU",
    components: {InputForm, MapsVue},
    props: {
        num_cu: Number,
        title: String,
        cu_infos : Object
    },
    methods: {
        move,
        stopMove,
        closeFloatMenu,
        reduceFloatMenu,
        epingleFloatMenu,
    },
    data (){
        return{
            color : '',
            tier : {},
            dossier : {}
        }
    },
    watch: {
        cu_infos(){
            console.log(this.cu_infos.features[0].properties);
            // let tier = volt.o(this.ne_infos.features[0].properties.tier);
            let dossier = volt.o(this.cu_infos.features[0].properties);
            this.tier = {
                "Nom" : dossier['TIENOM']
            }
            this.dossier = {
                "Numéro de dossier" : dossier['PRO_CODE'],
                "Adresse" : dossier['ADR_CODE'] +' '+ dossier['ADR_ADR1'] +' '+ dossier['ADR_ADR2'],
                "Commune" : dossier['ADR_CP'] +' '+ dossier['ADR_VILLE']
            }
        }
    }
}
var classPinTab = ['pin1', 'pin2', 'pin3', 'pin4', 'pin5', 'pin6', 'pin7'];
var tabValLeft = ['87px', '389px', '691px', '993px', '1295px', '1597px', '87px'];
var zIndex = 20;
var positionX = 0;
// var positionY = 0;
/* eslint-disable */
function menuMove(e) {
    var el = volt.tak(e.target.getAttribute('data-id'));
    if (el !== null) {
        if (parseInt(el.style.left) + el.scrollWidth <= window.innerWidth - 5 || el.style.left === '') {
            if (e.clientX - positionX > 2) {
                el.style.left = e.clientX - positionX + 'px';
            }
        } else if (parseInt(el.style.left) + el.scrollWidth >= window.innerWidth - 5) {
            el.style.left = parseInt(el.style.left) - 5 + "px";
        }
        if (parseInt(el.style.top) + el.scrollHeight <= window.innerHeight - 5 || el.style.top === '') {
            el.style.top = e.clientY - 15 + 'px';
        } else if (parseInt(el.style.top) + el.scrollHeight >= window.innerHeight - 5) {
            el.style.top = parseInt(el.style.top) - 5 + "px";
        }

    } else {
        stopMove(e);
    }
}

function move(e) {
    positionX = e.layerX;
    var el = volt.tak(e.target.getAttribute('data-id'));
    volt.ael(document, 'mousemove', menuMove);
    e.target.style.height = "30px";
    el.style.opacity = "0.5";
    el.style.boxShadow = "";
    el.style.zIndex = zIndex;
}

function stopMove(e) {
    zIndex++;
    var els = [];
    var elBars = [];
    document.removeEventListener('mousemove', menuMove);
    var el = volt.tak(e.target.getAttribute('data-id'));
    var elBar = volt.tak('top-bar-' + e.target.getAttribute('data-id'));
    if (el === null) {
        volt.taks('.menu-float-top-bar', '', false).forEach((topBarre) => {
            elBars.push(topBarre);
        })
        volt.taks('.menu-float', '', false).forEach((fMenu) => {
            els.push(fMenu);
        });
    } else {
        els.push(el);
        elBars.push(elBar);
    }
    els.forEach((el, i) => {
        elBars[i].style.height = "";
        el.style.opacity = "";
    })
}

function closeFloatMenu(e) {
    volt.taks('.value-select-field', '', false).forEach((elPop) => {
        elPop.style.border = '';
    });
    volt.taks('.select-choices-conform-field', '', false).forEach((elPop) => {
        elPop.style.display = '';
    });
    volt.taks('.fa-pen-alt', '', false).forEach((elPop) => {
        elPop.style.color = '';
    });
    var el = volt.tak(e.target.getAttribute('data-id'));
    el.style.transition = "0.7s";
    el.style.transform = "scale(0)";
    setTimeout(() => {
        el.style.transition = "";
        el.style.top = "";
        el.style.left = "";
        classPinTab.forEach((cl) => {
            el.classList.remove(cl);
            el.setAttribute('data-pin', "0");
        });
    }, 500);
}

function reduceFloatMenu(e) {
    volt.taks('.value-select-field', '', false).forEach((elPop) => {
        elPop.style.border = '';
    });
    volt.taks('.select-choices-conform-field', '', false).forEach((elPop) => {
        elPop.style.display = '';
    });
    volt.taks('.fa-pen-alt', '', false).forEach((elPop) => {
        elPop.style.color = '';
    });
    zIndex++;
    var oc = volt.tak('outils-content-' + e.target.getAttribute('data-id'));
    var el = volt.tak('detail-content-' + e.target.getAttribute('data-id'));
    var flm = volt.tak('menu-float-' + e.target.getAttribute('data-id'));
    var tb = volt.tak('top-bar-menu-float-' + e.target.getAttribute('data-id'));
    var ic = volt.tak('icon-reduce-menu-float-content-' + e.target.getAttribute('data-id'));
    var dc = volt.tak('menu-float-content-' + e.target.getAttribute('data-id'));
    flm.style.zIndex = zIndex;
    if (ic.className === "fas fa-minus") {
        oc.style.display = "none";
        el.style.height = "0";
        el.style.padding = "0";
        tb.style.width = "200px";
        dc.style.border = 'none';
        setTimeout(() => {
            flm.style.width = "300px";
            if (flm.getAttribute('data-pin') === "1") {
                epingleFloatMenu(e);
            }
        }, 500);
        ic.className = "fas fa-angle-down";
    } else {
        if (flm.getAttribute('data-pin') === "1") {
            classPinTab.forEach((cl) => {
                flm.classList.remove(cl);
            });
            flm.style.top = parseInt(flm.style.top) + 30 + 'px';
        }
        if (el.getAttribute('data-height') !== undefined && el.getAttribute('data-height') !== null) {
            el.style.height = el.getAttribute('data-height');
        } else {
            el.style.height = "";
        }
        if (el.getAttribute('data-width') !== undefined && el.getAttribute('data-width') !== null) {
            el.style.width = el.getAttribute('data-width');
        } else {
            el.style.width = "";
        }
        tb.style.width = ""
        flm.style.width = "";
        ic.className = "fas fa-minus";
        el.style.padding = "";
        dc.style.border = '';
        setTimeout(() => {
            oc.style.display = "";
            var diffAffY = (window.innerHeight - e.clientY);
            var diffAffX = (window.innerWidth - e.clientX);
            if (diffAffY < flm.scrollHeight) {
                var inter1 = setInterval(() => {
                    flm.style.top = parseInt(flm.style.top) - 50 + 'px';
                    if (parseInt(flm.style.top) <= 50 || parseInt(flm.style.top) <= window.innerHeight - flm.scrollHeight - 70) {
                        clearInterval(inter1);
                    }
                }, 0.005);
            }
            if (diffAffX < flm.scrollWidth) {
                var inter2 = setInterval(() => {
                    flm.style.left = parseInt(flm.style.left) - 50 + 'px';
                    if (parseInt(flm.style.left) < 100 || parseInt(flm.style.left) <= window.innerWidth - flm.scrollWidth - 50) {
                        clearInterval(inter2);
                    }
                }, 0.005);
            }
        }, 500);
    }
}

function epingleFloatMenu(e) {
    volt.tak('epingle-menu-float-' + e.target.getAttribute('data-id')).style.display = 'none';
    volt.tak('pointe-menu-float-' + e.target.getAttribute('data-id')).style.display = 'block';
    var ic = volt.tak('icon-reduce-menu-float-content-' + e.target.getAttribute('data-id'));
    var el = volt.tak('menu-float-' + e.target.getAttribute('data-id'));
    if (el.getAttribute('data-pin') === "0" || el.getAttribute('data-pin') === null) {
        if (ic.className === "fas fa-minus") {
            reduceFloatMenu(e);
        }
    }
    var utilPinTab = [];
    var utilLeftTab = [];
    var inutilPinTab = [];
    var inutilLeftTab = [];
    classPinTab.forEach((cl, index) => {
        volt.taks('.' + cl, '', false).forEach((e) => {
            utilPinTab.push(cl);
            utilLeftTab.push(index);
        })
    });
    classPinTab.forEach((cl, index) => {
        if (!utilPinTab.includes(cl)) {
            inutilPinTab.push(cl)
        }
        if (!utilLeftTab.includes(index)) {
            inutilLeftTab.push(index)
        }
    });
    if (el.style.top !== "10px") {
        countPinMenu++;
        el.style.top = "10px";
        el.style.left = tabValLeft[inutilLeftTab[0]];
        el.setAttribute('data-pin', "1");
        el.classList.add(inutilPinTab[0])
        el.style.boxShadow = "none";
    }
    // }
}
</script>

<style scoped>
.menu-float {
    position: fixed;
    top: 10%;
    left: 20%;
    transform: scale(0);
    background-color: #BBBBBEdd;
    height: 700px;
    box-shadow: 1px 1px 5px #212121;
    z-index: 3000;
    width: 60%;
}

.menu-float-content {
    width: 100%;
    background-color: #BBBBBEdd;
    transition: 0.7s;
    border-radius: 10px;
    margin-top: 5px;
}

.detail-content-floatmenu {
    height: 660px;
}

.menu-float-top-bar {
    display: none;
    color: #dde3df;
    width: 80%;
    font-size: 16px;
    background-color: #2e3133;
    padding-left: 10px;
    padding-top: 3px;
    cursor: pointer;
    border-radius: 20px;
    transition: 0.5s;
    height: 91%;
    text-align: start;
    float: left;
}

.closed-menu-float {
    font-size: 30px;
    width: 100%;
}


.fas {
    cursor: pointer;
    font-size: 17px;
}

.fa-times-circle {
    cursor: pointer;
    font-size: 25px;
    float: right;
    margin-right: 5px;
    margin-top: 5px;
}

.outils-contant-float {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(20, 1fr);
    height: 99%;
    max-height: 99%;
    background-color: #eeeeee;
    border-radius: 10px;
    margin: 0 10px 5px 10px;
    overflow: hidden;
    padding: 5px;
    border: solid 1px #f3b61b;
}
.form-CU-div{
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 8;
    grid-row-end: 21;
}
.form-CU-div2{
    grid-column-start: 10;
    grid-column-end: 21;
    grid-row-start: 8;
    grid-row-end: 21;
}
.form-row{
    margin: 10px;
    display: flex;
    flex-direction: row;
}
.title-pop-form{
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 7;
    border-bottom: solid 1px #f3b61b;
    text-align: left;
}
.subtitle-pop-form{
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 7;
    border-bottom: solid 1px #f3b61b;
    text-align: left;
}
</style>