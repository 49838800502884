<template>

    <!--  navbar v1-->
    <!--  <div id="nav-bar">-->
    <!--    <div id="logo-nav"><img id="logo-img" :src="logo"></div>-->
    <!--    <div id="menu-nav">-->
    <!--      <MenuLink v-for="choice in choices"-->
    <!--                :key="choice"-->
    <!--                :name="choice.name"-->
    <!--                :choices="choice.choices"-->
    <!--                @callYear = "callYear"-->
    <!--                @callResult = "callResult"-->
    <!--                @callChart = "callChart"-->
    <!--                @callEntity = "callEntity"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div v-if="dashboard === 'observatoire'" id="search-nav">-->
    <!--      <div class="search-control">-->
    <!--        <select class="select-search" id="select-search-navbar">-->
    <!--          <option value="">Sélectionner</option>-->
    <!--          <option v-for="option in options" :key="option" :value="option.link">{{option.label}}</option>-->
    <!--        </select>-->
    <!--        <input class="input-search" id="value-search-navbar"/>-->
    <!--        <button v-if="clickKpi > 0" type="button" class="btn-search" @click="callSearch">Rechercher dans la liste</button>-->
    <!--        <button v-else type="button" class="btn-search" @click="callSearch">Rechercher</button>-->
    <!--      </div>-->
    <!--      <span id="powered-by">Powered by <img id="logo-touriz" src="logo_touriz.png"></span>-->
    <!--    </div>-->
    <!--    <div v-else  id="search-nav"><span id="powered-by">Powered by <img id="logo-touriz" src="logo_touriz.png"></span></div>-->
    <!--  </div>-->


    <!--  navbar v2-->
    <div id="nav-bar">

        <div id="logo-nav-v2">
            <img id="logo-touriz-img" src="logo_touriz.png" alt="logo touriz">
            <h4 id="title-touriz-nav2">L'Observatoire des locations meublées touristiques<br>
                <div id="subtitle-touriz-nav2">(données ELAN {{ this.year }})</div>
            </h4>
        </div>
        <div id="menu-nav-v2">
            <img v-if="env.logo[entity]" id="logo-img-v2" :src="logo" alt="logo commune" />
            <img v-else id="logo-img-all" :src="logo" alt="logo commune"><span v-if="env.logo[entity] === undefined"
                                                                               style="font-size: 30px; font-style: italic; font-family: 'Arial Rounded MT Bold'">{{ entity.charAt(0).toUpperCase() + entity.slice(1) }}</span>
            <a v-if="env.rgpd[entity]" target="_blank" :href="env.rgpd[entity]" title="RGPD"><img id="rgpd-img-all" src="rgpd-2.jpg" alt="logo rgpd"/></a>
            <a v-else target="_blank" :href="env.rgpd['all']" title="RGPD"><img id="rgpd-img-all" src="rgpd-2.jpg" alt="logo rgpd"/></a>
            <div id="btn-navbar-select">
                <button class="btn-text-menu" id='btn-call-dashboard-coupdoeil' @click="callChart('coupdoeil')">
                    Observatoire
                </button>
                <button class="btn-text-menu" id='btn-call-dashboard-chart' @click="callChart('chart')">Mes rapports
                </button>
                <button v-if="dashboard === 'coupdoeil' && (list.name === 'totalAnnonces' || Object.keys(list).length === 0)" class="btn-icon-menu" @click="openSearchPop"><i class="fas fa-search"></i></button>
                <button class="btn-icon-menu" @click="openMenu"><i class="fas fa-cog"></i></button>
                <button class="btn-icon-menu" @click="openOnglet"><i class="fa-regular fa-clone"></i></button>
                <button v-if="user.level === 2" class="btn-icon-menu-admin" id="btn-call-dashboard-adminBoard"
                        @click="openAdmin">
                    <i class="fa-solid fa-users" style="margin-right: 5px"></i>
                    <i class="fa-solid fa-chart-line"></i>
                </button>
            </div>
            <PopParams
                :choices_select="choices_select"
                :choices_link="choices_link"
                :specif="specif"
                @callYear="callYear"
                @callResult="callResult"
                @callEntity="callEntity"
                @callMapFilter="callMapFilter"
                :year="year"
                :limit="limit"
                :entity="entity"
                :map_filter="map_filter"
                :kpis_type="kpis_type"
            />
            <PopSearch id="navbar" @callSearch="callSearch" />
        </div>
    </div>
</template>

<script>
// import MenuLink from "@/components/MenuLink";
import volt from "../volt";
import PopParams from "@/elements/PopParams";
import PopSearch from "@/elements/PopSearch.vue";

export default {
    name: "NavBar",
    components: {PopSearch, PopParams},
    // components: {MenuLink},
    emits: ["callYear", "callResult", "callChart", "callEntity", "callSearch", "callMapFilter", "resetListUser", "callAdminBoard"],
    props: {
        env: Object,
        user: Object,
        logo: String,
        entities: Array,
        options: Array,
        clickKpi: Number,
        dashboard: String,
        year: String,
        limit: Number,
        entity: String,
        map_filter: Number,
        specif: String,
        entity_select: Object,
        list : Object,
        kpis_type: String
    },
    mounted() {
        volt.tak('btn-call-dashboard-' + this.dashboard).style.backgroundColor = "#f3b61b"
        this.callEntities();
        this.callChoices();
        this.callYears();
    },
    watch: {
        specif() {
            this.callChoices();
        },
        entities() {
            this.callEntities();
            this.callChoices();
            this.callYears();
        },
        clickKpi() {
            if (this.clickKpi > 0) {
                this.widthSelect = "30%";
            } else {
                this.widthSelect = "40%";
            }
        },
        entity_select() {
            this.callChoices();
        }
    },
    methods: {
        openSearchPop() {
            this.closeMenu();
            if (volt.tak('pop-search-navbar').style.height === '0px' || volt.tak('pop-search-navbar').style.height === '') {
                volt.tak('pop-search-navbar').style.height = '10%';
                setTimeout(() => {
                    volt.tak('pop-search-content').style.display = 'flex';
                }, 200);
            } else {
                volt.tak('pop-search-content').style.display = 'none';
                volt.tak('pop-search-navbar').style.height = '0px';
            }
            volt.taks('.base-grid-chart', '', false).forEach((el) => {
                el.addEventListener('click', this.closeSearchPop, true);
            });
        },
        closeSearchPop() {
            setTimeout(() => {
                volt.tak('pop-search-content').style.display = 'none';
                volt.tak('pop-search-navbar').style.height = '0px';
                volt.tak('pop-search-input-navbar').value = '';
                volt.taks('.base-grid-chart', '', false).forEach((el) => {
                    el.addEventListener('click', this.closeSearchPop, true);
                });
            }, 50)
        },
        openAdmin() {
            this.$emit('callAdminBoard');
        },
        openOnglet() {
            let token = sessionStorage.getItem('token');
            let token1 = token.replace(/=/g, "equalium");
            let token2 = token1.replace(/\?/g, "questalium");
            let token3 = token2.replace(/\//g, "slashalium");
            let token4 = token3.replace(/\\/g, "antislashalium");
            let token5 = token4.replace(/&/g, "andalium");
            open(this.env.paths.front + '?id_token=' + token5 + '&entity=' + this.entity, '_blank');
        },
        callMenus(link) {
            if (this.name === 'Années') this.callYear(link);
            if (this.name === 'Résultats') this.callResult(link);
            if (this.name === 'Tableaux') this.callChart(link);
            if (this.name === 'Communes') this.callEntity(link);
            if (this.name === 'Cartographie') this.callMapFilter(link);
        },
        callMapFilter() {
            setTimeout(() => {
                this.$emit('callMapFilter');
            }, 100);
        },
        callYear() {
            setTimeout(() => {
                this.$emit('callYear');
            }, 100);
        },
        callResult() {
            setTimeout(() => {
                this.$emit('callResult');
            }, 100);
        },
        callChart(link) {
            // volt.taks('.btn-text-menu','', false).forEach((el)=>{
            //   el.style.color = "";
            // });
            // volt.tak('btn-call-dashboard-'+).style.color = "#f3b61b"
            volt.tableau = link;
            volt.select_vals = {};
            volt.sort_vals = {};
            setTimeout(() => {
                this.$emit('callChart');
                this.$emit('resetListUser');
            }, 100);
        },
        callYears() {
            this.years = [];
            let year_start = '2019';
            let year_end = this.env.year
            // this.years.push({name: this.year, link: this.year})
            for (let i = parseInt(year_end); i >= parseInt(year_start); i--) {
                this.years.push({name: i.toString(), link: i.toString()})
            }
            this.choices_select[0]['choices'] = this.years;
        },
        callEntity() {
            setTimeout(() => {
                volt.tak('pop-params-content').style.display = 'none';
                volt.tak('pop-params-div').style.height = '0px';
                this.$emit('callEntity');
            }, 100);
        },
        callEntities() {
            if (this.entities.length > 1) {
                // this.communes.push({name: this.entity, link: this.entity})
                for (const entity of this.entities) {
                    this.communes.push({name: volt.firstToUpper(entity.entity), link: entity.entity})
                }
                this.choices_select[2]['choices'] = this.communes;
            } else {
                this.communes.push({name: volt.firstToUpper(this.entity), link: this.entity})
                this.choices_select[2]['choices'] = this.communes;
            }
        },
        callSearch(arg) {
            // if (volt.tak('select-search-navbar').value !== '' && volt.tak('value-search-navbar').value !== '') {
            //     let data = {
            //         field: volt.tak('select-search-navbar').value,
            //         value: volt.tak('value-search-navbar').value
            //     }
            //     volt.tak('value-search-navbar').value = '';
            //     // this.$emit('callSearch')
            //     setTimeout(() => {
            //         this.$emit('callSearch', data);
            //     }, 100);
            // }
            let data = {
                field: 'all',
                value: arg.value
            }
            setTimeout(() => {
                this.$emit('callSearch', data);
            }, 100);
        },
        openMenu() {
            this.closeSearchPop();
            if (volt.tak('pop-params-div').style.height === '0px' || volt.tak('pop-params-div').style.height === '') {
                volt.tak('pop-params-div').style.height = '60%';
                setTimeout(() => {
                    volt.tak('pop-params-content').style.display = 'flex';
                }, 200);
            } else {
                volt.tak('pop-params-content').style.display = 'none';
                volt.tak('pop-params-div').style.height = '0px';
            }
            volt.taks('.base-grid-chart', '', false).forEach((el) => {
                el.addEventListener('click', this.closeMenu, true);
            });

        },
        closeMenu() {
            setTimeout(() => {
                volt.tak('pop-params-content').style.display = 'none';
                volt.tak('pop-params-div').style.height = '0px';
                volt.taks('.base-grid-chart', '', false).forEach((el) => {
                    el.addEventListener('click', this.closeMenu, true);
                });
            }, 50)
        },
        callChoices() {
            if (this.specif === 'elan') {
                let choices = [
                    {name: 'Iris', link: 1}
                ]
                let test_arrondissement = false;
                let test_ept = false;
                if (this.entity_select.pref_arr !== undefined && this.entity_select.pref_arr !== "") {
                    test_arrondissement = true
                }
                if (this.entity_select.type !== undefined && this.entity_select.type === "2") {
                    test_ept = true
                }
                if (test_arrondissement) {
                    choices = [
                        {name: 'Arrondissement', link: 2},
                        {name: 'Iris', link: 1}
                    ]
                }
                if (test_ept) {
                    choices = [
                        {name: 'Communes', link: 3},
                    ]
                }
                this.choices_link = [
                    {
                        name: 'Cartographie',
                        choices: choices
                    }
                    // {
                    //     name: 'Affichage',
                    //     choices: [
                    //         {name: 'Cadastre', link: 'CADASTRE'},
                    //         {name: 'PLU', link: 'PLU'}
                    //     ]
                    // },
                ]
            } else if (this.specif === 'ept') {
                this.choices_link = [
                    {
                        name: 'Cartographie',
                        choices: [
                            {name: 'Commune', link: 3},
                            // {name: 'Arrondissement', link: 2},
                            // {name: 'Iris', link: 1}
                        ]
                    },
                    // {
                    //   name : 'Affichage',
                    //   choices : [
                    //     {name: 'Cadastre', link: 'CADASTRE'},
                    //     {name: 'PLU', link: 'PLU'}
                    //   ]
                    // },
                ]
            }
            // else {
            //     this.choices_link = [
            //         {
            //             name: 'Affichage',
            //             choices: [
            //                 {name: 'Cadastre', link: 'CADASTRE'},
            //                 {name: 'PLU', link: 'PLU'}
            //             ]
            //         },
            //     ]
            // }
        }
    },
    data() {
        return {
            widthSelect: "40%",
            communes: [],
            years: [],
            choices_select: [
                {
                    name: 'Années',
                    choices: this.years
                },
                {
                    name: 'Résultats',
                    choices: [
                        {name: '20', link: '20'},
                        {name: '50', link: '50'},
                        {name: '100', link: '100'},
                        {name: '200', link: '200'},
                    ]
                },
                {
                    name: 'Communes',
                    choices: this.communes
                },
            ],
            choices_link: [
                {
                    name: 'Cartographie',
                    choices: [
                        {name: 'Commune', link: 0},
                        {name: 'Arrondissement', link: 2},
                        {name: 'Iris', link: 1}
                    ]
                },
                // {
                //     name: 'Affichage',
                //     choices: [
                //         {name: 'Cadastre', link: 'CADASTRE'},
                //         {name: 'PLU', link: 'PLU'}
                //     ]
                // },
            ]
        }
    }

}
</script>

<style scoped>
#nav-bar {
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    /*grid-template-rows: repeat(4, 1fr); */
    /*height: 100%;*/
    /*max-height: 100%;*/
    /*padding-bottom: 10px;*/
}

#logo-nav {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 5;
}

#logo-commune {
    /*width: 100%;*/
    /*height: 100%;*/
}

#logo-nav-v2 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 4;
    display: flex;
    padding-left: 20px;
    align-items: center;
}

#menu-nav {
    display: flex;
    flex-direction: row;
    grid-column-start: 4;
    grid-column-end: 15;
    grid-row-start: 3;
    grid-row-end: 4;
    padding-top: 10px;
}

#menu-nav-v2 {
    display: grid;
    flex-direction: row;
    grid-column-start: 9;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-template-columns: repeat(10, 1fr);
    /*padding-top: 10px;*/
    align-items: center;
}

#btn-navbar-select {
    display: flex;
    justify-content: space-evenly;
    grid-column-start: 6;
    grid-column-end: 11;
}

.btn-icon-menu {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-size: 25px;
    margin-left: 5px;
}

.btn-icon-menu-admin {
    cursor: pointer;
    width: 80px;
    height: 40px;
    border: solid 1px;
    border-radius: 10px;
    font-size: 20px;
    margin-left: 10px;
    background-color: #9d0505;
    color: #fefefe;
    margin-right: 10px;
}

.btn-text-menu {
    cursor: pointer;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    background-color: #d6d6d6;
    margin-left: 5px;
}

.btn-icon-menu:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

.btn-icon-menu-admin:active {
    box-shadow: inset 1px 1px 5px #330202;
}

.btn-text-menu:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}

#search-nav {
    grid-column-start: 15;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 5;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1px;
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    max-height: 100%;
}

#logo-img {
    width: 100%;
    padding-left: 20px;
}

#logo-img-v2 {
    width: 250px;
    /*height: 70px;*/
    padding: 10px 10px 10px 10px;
}

#logo-img-all {
    /*width: 250px;*/
    height: 70px;
    padding: 10px 10px 10px 10px;
}
#rgpd-img-all{
    width: 70px;
}

#powered-by {
    font-size: 8px;
    padding-bottom: 5px;
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
    /*text-align: end;*/
    /*padding-right: 20px;*/
}

#logo-touriz {
    width: 60px;
    position: relative;
    top: 8px;
}

#logo-touriz-img {
    /*width: 10%;*/
    width: 120px;
    /*height: 40%;*/
    margin-right: 20px;
}

#title-touriz-nav2 {

}

#subtitle-touriz-nav2 {
    font-size: 13px;
    font-weight: normal;
    text-align: start
}

.search-control {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
    /*background-color: #f3b61b;*/
    display: flex;
    flex-direction: row;
    justify-content: start;
    /*padding: 0 0 30px 10px;*/
}

.select-search {
    border: none;
    border-radius: 5px;
    width: v-bind(widthSelect);
    margin-right: 5px;
}

.input-search {
    border: none;
    border-radius: 5px;
    width: 40%;
    margin-right: 5px;
    outline: none;
}

.btn-search {
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-search:active {
    box-shadow: inset 1px 1px 5px #7a7a7a;
}
@media (max-width: 1700px) {
    .btn-text-menu {
        margin: 5px;
        width: 300px;
        height: 50px;
    }

    .btn-icon-menu {
        height: 50px;
        margin: 5px;
    }

    .btn-icon-menu-admin {
        height: 50px;
        width: 250px;
        margin: 5px;
    }
    #btn-navbar-select {
        grid-column-start: 3;
    }
}
@media (max-width: 1250px) {
    #logo-img-v2 {
        width: 200px;
        grid-column-start: 2;
        grid-row-start: 1;
        padding: 10px 5px 15px 0;
    }

    #logo-nav-v2 {
        grid-column-start: 1;
        grid-column-end: 10;
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 15px 0 0 10px;
    }

    #title-touriz-nav2 {
        margin: 5px 0 10px 0;
        font-size: 15px;
    }

    #logo-touriz-img {
        width: 90px;
        padding: 5px;
        margin-right: 5px;
    }

    #subtitle-touriz-nav2 {
        font-size: 12px;
        font-weight: normal;
        text-align: center;
    }

    #menu-nav-v2 {
        align-items: start;
        grid-column-start: 12;
        grid-column-end: 21;
        grid-row-start: 2;
        grid-row-end: 4;
    }

    #btn-navbar-select {
        display: flex;
        justify-content: end;
        grid-column-start: 1;
        grid-column-end: 21;
        padding-bottom: 5px;
    }

    .btn-text-menu {
        margin: 5px;
        width: 100px;
        height: 20px;
        font-size: 12px;
        background-color: #d6d6d6;
    }

    .btn-icon-menu {
        width: 40px;
        height: 20px;
        font-size: 12px;
        margin: 5px;
    }

    .btn-icon-menu-admin {
        width: 70px;
        height: 20px;
        font-size: 12px;
        margin: 5px;
    }
}

@media (max-width: 700px) {
    #logo-img-v2 {
        width: 150px;
        grid-column-start: 2;
        grid-row-start: 1;
        padding: 10px 5px 15px 0;
    }

    #logo-nav-v2 {
        grid-column-start: 1;
        grid-column-end: 21;
        grid-row-start: 1;
        grid-row-end: 1;
        padding-left: 10px;
    }

    #title-touriz-nav2 {
        display: none;
    }

    #logo-touriz-img {
        display: none;
    }

    #subtitle-touriz-nav2 {
        display: none;
    }

    #menu-nav-v2 {
        align-items: start;
        grid-column-start: 1;
        grid-column-end: 21;
        grid-row-start: 2;
        grid-row-end: 4;
    }

    #btn-navbar-select {
        display: flex;
        justify-content: end;
        grid-column-start: 1;
        grid-column-end: 21;
        padding-bottom: 5px;
    }

    .btn-text-menu {
        margin: 5px;
        width: 100px;
        height: 20px;
        font-size: 12px;
        background-color: #d6d6d6;
    }

    .btn-icon-menu {
        width: 40px;
        height: 20px;
        font-size: 12px;
        margin: 5px;
    }

    .btn-icon-menu-admin {
        width: 70px;
        height: 20px;
        font-size: 12px;
        margin: 5px;
    }
}
</style>