<template>
  <div @click="callLinkPri" class="simple-block">
    <div class="kpi-subtitle" :style="style.title">{{title}}</div>
    <div v-if="link.name === ''"  class="kpi-alone" :style="style.value" >  <div class="kpi-value">{{ number_format(value) }}</div></div>
    <div v-else class="kpi-alone-link" :style="style.value" :data-type="link.type" >  <div class="kpi-value">{{ number_format(value) }}</div></div>
    <div v-if="evolution && evolution !== ''">
      <div class="evo-div">
        <div v-if="Number(evolution) >= 0" class="element-evolution-green">+{{ number_format(evolution) }} %</div>
        <div v-else class="element-evolution-red"> {{ number_format(evolution) }} %</div>
        <div v-if="title === 'Population (source INSEE 2020)'" class="multi-element-versus">vs {{ 2019 }}</div>
        <div v-else class="multi-element-versus">vs {{ parseInt(year) - 1 }}</div>
      </div>
    </div>
    <div v-if="versus && versus.unit !== ''">
      <div class="evo-div">
        <div v-if="Number(versus.value) >= 0" class="element-evolution-green">{{ number_format(versus.value) }} %</div>
        <div v-else class="element-evolution-red"> {{ number_format(versus.value) }} %</div>
        <div class="multi-element-versus">{{ versus.unit }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import volt from "@/volt";

export default {
  name: "SimpleBlock",
  emits: ["callLinkPri", "callLinkSec"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    versus : Object,
    size : String,
    year : String,
    evolution : [String, Number]
  },
  data(){
    return{
      backcolor : ""
    }
  },
  methods:{
    number_format(number){
      return volt.number_format(number);
    },
    callLinkPri(){
      if(this.link.name !== ''){
        volt.list =  {
          name : this.link.name,
          value : this.link.value,
          size : this.link.size,
          type : this.link.type,
          limit : this.limit,
          offset : 0,
          order : 1,
          by : ''
        };
        this.$emit('callLinkPri');
      }
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkSec');
    }
  }
}
</script>

<style scoped>
.simple-block{
  height: 100%;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  /*margin: 2px;*/
  padding: 15px;
  /*font-weight: v-bind(weigthTitle);*/
  display: flex;
  flex-direction: row;
  height: 20%;
  /*border: solid 1px #f3751b;*/
}
.kpi-alone-link{
  color : #d89d06;
  cursor: pointer;
}
.kpi-value{
  /*border: solid 1px #f3751b;*/
  margin-top: 20px;
  /*font-weight: bold;*/
  font-size: 30px;
}
.evo-div{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 50px;
}
.element-evolution-green{
  color: green;
}
.element-evolution-red{
  color: red;
}
.multi-element-versus{
  font-size: 11px;
  text-align: center;
}
@media (max-width: 1700px) {
  .kpi-subtitle{
    font-size: 13px;
    padding: 10px;
  }
  .kpi-value{
    margin-top: 50px;
    font-size: 30px;
  }
}
@media (max-width: 1250px) {
  .kpi-subtitle{
    text-align: start;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 45%;
  }
  .kpi-value{
    margin-top: 5px;
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .kpi-subtitle{
    height: 10%;
  }
}
</style>